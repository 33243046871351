import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect
} from 'react';
import { getLocalData } from 'src/utils';

const ChatContext = createContext();
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const twilioNumber = getLocalData('twilioNumber')?.replace('+', '');
  const SOCKET_URL = `${process.env.REACT_APP_SOCKET_URL}?number=%2B${twilioNumber}`;
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeScreen, setActiveScreen] = useState(0);
  const [selectedSearchClient, setSelectedSearchClient] = useState({});
  const [allUsersChat, setAllUsersChat] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [notification, setNotification] = useState(false);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [arrivalMessages, setArrivalMessages] = useState({});
  const [locationDetails, setLocationDetails] = useState({});
  const socket = useRef(null);
  const open = Boolean(anchorEl);

  const onSocketOpen = useCallback(() => {
    setIsConnected(true);
    console.warn('WebSocket Connected!');
  }, []);

  const onSocketMessage = useCallback((data) => {
    const parsedData = JSON.parse(data);
    const messageObject = parsedData?.message?.message;
    setArrivalMessages(messageObject);
  }, []);

  useEffect(() => {
    if (!isConnected || Object?.keys(arrivalMessages)?.length === 0) {
      return;
    }

    if (!open) {
      setNotification(true);
      setReceivedMessages((prev) => [...prev, arrivalMessages]);
    } else if (
      selectedSearchClient?.sortKey ||
      selectedSearchClient?.clientId
    ) {
      setAllUsersChat((prev) => [...prev, arrivalMessages]);
    } else {
      setReceivedMessages((prev) => [...prev, arrivalMessages]);
    }
  }, [arrivalMessages]);
  const onConnect = useCallback(() => {
    if (socket?.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(SOCKET_URL);
      socket?.current?.addEventListener('open', onSocketOpen);
      socket.current.addEventListener('message', (event) => {
        console.warn('receivemessage');
        onSocketMessage(event?.data);
      });
    }
  }, []);

  useEffect(() => {
    onConnect();
  }, []);

  const handleClearStates = () => {
    setAllUsersChat([]);
    setReceivedMessages([]);
    setArrivalMessages({});
    setActiveScreen(0);
    setSelectedSearchClient({});
  };

  return (
    <ChatContext.Provider
      value={{
        activeScreen,
        setActiveScreen,
        selectedSearchClient,
        setSelectedSearchClient,
        anchorEl,
        setAnchorEl,
        allUsersChat,
        setAllUsersChat,
        notification,
        setNotification,
        handleClearStates,
        isConnected,
        receivedMessages,
        locationDetails,
        setLocationDetails
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
