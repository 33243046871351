import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Avatar,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Card,
  TableContainer,
  IconButton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState } from 'react';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import TrophyAssign from './TrophyAssign';
import AddAlertNotes from './AddAlertNotes/AddAlertNotes';
import Alert from './Alert';
import Notes from './Notes';
import PurchaseHistory from './PurchaseHistory';
import UpcomingSchedule from './UpcomingSchedule';
import Credits from './Credits';
import { DynamicTabs } from '../../../components/ClientManagement';
import ClientContract from './ClientContract';
import PastVist from './PastVisit';
import CustomAlert from 'src/components/Global/CustomAlert';
import WarningIcon from '@mui/icons-material/Warning';
import { ApiService } from 'src/services/ApiService';
import { useNavigate } from 'react-router-dom';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ClientDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

const ClientDetails = ({
  data,
  getItemForEdit,
  getEventToRefresh,
  setShowScreen,
  getSelectedPet,
  setSelectedClientItem,
  selectedClientItem
}) => {
  const navigate = useNavigate();

  let clientData = data;
  let clientDetails = clientData;

  const [isLoading, setIsLoading] = useState(false);

  const userId = getLocalData('userId');
  const [openPet, setOpenPet] = useState(false);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [getClientId, setClientId] = useState('');
  let clientId;
  const [open, setOpen] = React.useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [tabList, setTabList] = useState([]);
  const [status, setStatus] = useState(1);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');

  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');

  // set the pet details
  const [allPets, setAllPets] = useState([]);

  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const [getPetItem, setGetPetItem] = React.useState([]);
  /* State for opening of add team dialog box */
  const [isAddTeamOpen, setIsAddTeamOpen] = useState(false);
  const [openAddteam, setopenAddteam] = useState(false);
  const [openAlertNotes, setOpenAlertNotes] = useState(false);
  const [openAlertNotesDialog, setOpenAlertNotesDialog] = useState(false);
  const [invokeRefresh, setInvokeRefresh] = useState(false);
  const [getAlertReminder, setGetAlertReminder] = useState([]);

  let petPlaceholder =
    'https://franchise-management-production.s3.amazonaws.com/placeholder/dogplaceholder.png';

  // calculate age
  const calculateAge = (value) => {
    if (!value) {
      return '';
    }

    const today = new Date();

    const birthMonth = parseInt(value.slice(0, 2), 10);
    const birthDay = parseInt(value.slice(3, 5), 10);
    const birthYear = parseInt(value.slice(6, 10), 10);

    let ageYears = today.getFullYear() - birthYear;
    let ageMonths = today.getMonth() + 1 - birthMonth; // getMonth() returns 0-11
    let ageDays = today.getDate() - birthDay;

    if (ageDays < 0) {
      ageMonths -= 1;
      ageDays += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (ageMonths < 0) {
      ageYears -= 1;
      ageMonths += 12;
    }

    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    const diffTime = today - birthDate;
    const ageWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));

    if (ageWeeks <= 15) {
      return `${ageWeeks} week${ageWeeks !== 1 ? 's' : ''}`;
    }

    if (ageYears === 0 && ageMonths === 0 && ageDays === 0) {
      return 'Newborn';
    }

    let result = '';

    if (ageYears > 0) {
      result += `${ageYears} year${ageYears !== 1 ? 's' : ''} `;
    }

    if (ageMonths > 0) {
      result += `${ageMonths} month${ageMonths !== 1 ? 's' : ''} `;
    }

    if (ageDays > 0) {
      result += `${ageDays} day${ageDays !== 1 ? 's' : ''} `;
    }

    return result.trim();
  };

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      deleteClient();
    }
    setOpen(false);
  };

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');

    let cls = 'edit_btn_' + lid;

    getItemForEdit(cls);

    setShowScreen(2);
  };

  // Method to add another pet
  const addAnotherPet = () => {
    setShowScreen(4);
  };

  // useeffect state starts here
  useEffect(() => {
    clientId = clientDetails?.sortKey;

    localStorage.setItem('clientId', clientId);

    if (clientId) getClientDetails(clientId);

    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [clientDetails]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [clientId]);

  // Method to delete the client
  const deleteClient = async () => {
    // Now show the loader
    setIsLoading(true);
    // call the api to delete the location
    let url = `${process.env.REACT_APP_API_END_POINT}client`;

    let locId =
      isFranchisor === 'true'
        ? localStorage.getItem('SelectedLocationId')
        : clientDetails?.locationId;

    const dataToDelete = {
      locationId: locId,
      email: clientDetails?.email,
      clientId: clientDetails?.sortKey,
      userId: userId
    };
    // execute the api
    try {
      const data = await ApiService.delete(`${url}`, dataToDelete);

      if (data) {
        setOpenMessageDialog(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setShowError(true);
      setErrorMessage(error?.message);
      setIsLoading(false);
    }
  };

  // Method to get the client details

  const getClientDetails = async (clientId) => {
    // call the api to get the location details
    setIsLoading(true);
    let encodedClientId = encodeURIComponent(clientId);
    let encodedLocationId = encodeURIComponent(locationId);
    let url = `${process.env.REACT_APP_API_END_POINT}client/getClientDetails?clientId=${encodedClientId}&locationId=${encodedLocationId}`;
    try {
      const data = await ApiService.get(`${url}`);

      if (data) {
        setIsLoading(false);
        // set the location details
        let items = data.data.Items;
        setSelectedClientItem(items);
        if (items) clientDetails = items[0];
        let petage =
          clientDetails?.pets && clientDetails?.pets.length > 0
            ? clientDetails?.pets[0].birthDate
            : '';
        if (petage) setAge(petage);

        // set pet details
        if (clientDetails?.pets && clientDetails?.pets.length > 0) {
          setAllPets(clientDetails?.pets);
          setClientId(clientDetails?.sortKey);
          setInvokeRefresh(true);
        } else {
          setAllPets([]);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      // set error message
      setShowError(true);
      setErrorMessage(error?.message);
    }
  };

  const setAge = (age) => {
    let finalage = calculateAge(age);
    finalage = finalage.replace(/-/g, ' ');

    return finalage;
  };

  // Method to edit the pet
  const editPet = (petId, e, getClientId) => {
    e.stopPropagation();
    e.preventDefault();

    getSelectedPet(petId, getClientId);
    setShowScreen(5);
  };

  const handleCloseDetails = () => {
    setShowScreen(0);
  };

  // View location details
  const handleViewPetDetails = (petId, e, getClientId, status) => {
    e.preventDefault();
    e.stopPropagation();
    if (e && e.target && !e.target.classList.contains('MuiBackdrop-root')) {
      getSelectedPet(petId, getClientId, status);
      setShowScreen(6);
    }
  };

  const handleDelete = () => {
    setShowScreen(0);
    setOpenMessageDialog(false);

    getEventToRefresh();
  };

  const openMenu = (pet, event) => {
    event.preventDefault();
    event.stopPropagation();
    setGetPetItem(pet);

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (pet, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'addAlert') {
      setOpenAlertNotes(true);
      setOpenAlertNotesDialog(true);
    } else if (value === 'assignTrophy') {
      setIsAddTeamOpen(true);
    }
  };

  const handleOkClick = (value) => {
    if (value === 'close') {
      setShowScreen(0);
    } else if (value === 'ok') {
      navigate('/schedules');
    }
  };

  const getAllAppointments = async () => {
    try {
      let payload = {
        clientParentId: getClientId,
        clientChildId: getPetItem?.sortKey,
        type: 'upcoming',
        locationId: locationId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointmentHistory`,
        payload
      );

      let filteredData = [];
      response?.data?.Items?.forEach((item) => {
        if (
          item.clientDetails[0].status !== 'earlyCancel' &&
          item.clientDetails[0].status !== 'lateCancel' &&
          item.clientDetails[0].status !== 'refund'
        ) {
          filteredData.push(item);
        }
      });
      if (
        (response.statusCode === 200 || response.statusCode === 400) &&
        filteredData?.length > 0
      ) {
        setOpenSchedulePopup(true);
      } else {
        setOpenPet(true);
      }
    } catch (error) {
      if (error.statusCode === 400 && error.message === 'No record Founds') {
        setOpenPet(true);
      }
      console.log(error, 'error');
    }
  };

  const dogStatusHandle = (pet, event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(null);
    if (value === 'deactivate') {
      getAllAppointments();
      setStatus(0);
      // setOpenPet(true);
    } else if (value === 'activate') {
      setStatus(1);
      setOpenPet(true);
    }
  };
  const handlePetClose = (value) => {
    if (value === 'Cancel') {
      setOpenPet(false);
    } else if (value === 'Delete') {
      deactivatePet();
    }
  };

  const deactivatePet = async () => {
    setIsLoading(true);
    let payload = {
      clientId: getPetItem.clientId,
      id: getPetItem.petId,
      updatedBy: userId,
      status: status
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/deactivatePet`,
        payload
      );
      if (response.statusCode && !response.error) {
        setIsLoading(false);
        setOpenPet(false);
        setShowSuccessMessage(true);
        setSuccessMessage(
          `Child sucessfully ${status === 0 ? 'deactivated' : 'activated'}`
        );
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPet(false);
      setErrorMessage(error?.message || 'Something went wrong');
    }
  };
  // Method to refresh the alert and notes tab
  const refreshAlertNotes = () => {
    setInvokeRefresh(true);
  };

  useEffect(() => {
    // if (getClientId) {
    setTabList([
      {
        label: 'Alerts',
        customLabel: 'Alerts',
        component: (
          <Alert
            clientId={getClientId}
            invokeRefresh={invokeRefresh}
            setInvokeRefresh={setInvokeRefresh}
          />
        )
      },
      {
        label: 'Notes',
        customLabel: 'Notes',
        component: (
          <Notes
            clientId={getClientId}
            invokeRefresh={invokeRefresh}
            setInvokeRefresh={setInvokeRefresh}
          />
        )
      },
      {
        label: 'Purchase History',
        customLabel: 'Purchase History',
        component: <PurchaseHistory clientId={getClientId} allPets={allPets} />
      },
      {
        label: 'Upcoming Schedule',
        customLabel: 'Upcoming Schedule',
        component: <UpcomingSchedule clientId={getClientId} allPets={allPets} />
      },
      {
        label: 'Past Visit',
        customLabel: 'Past Visit',
        component: <PastVist clientId={getClientId} allPets={allPets} />
      },
      {
        label: 'Credits',
        customLabel: 'Credits',
        component: <Credits clientId={getClientId} />
      },
      {
        label: 'Contract',
        customLabel: 'Contract And Membership',
        component: <ClientContract clientId={getClientId} />
      }
    ]);
    setLoading(false);
    // }
  }, [getClientId, invokeRefresh]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAlertReminders();
  }, []);

  const getAlertReminders = async () => {
    setIsLoading(true);
    let encodedClientId = encodeURIComponent(clientId);
    let encodedLocation = encodeURIComponent(locationId);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_CHAT_API_END_POINT}client/alertVaccination?clientId=${encodedClientId}&locationId=${encodedLocation}&timeZone=${timeZone}`
      );
      if (response.statusCode && !response.error) {
        setIsLoading(false);
        setGetAlertReminder(response?.data?.alerts);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error?.message || 'Something went wrong');
    }

    // setAlertData(clientAlerts);
  };
  const handleClosePopUp = () => {
    getClientDetails(getPetItem.clientId);
    setShowSuccessMessage(false);
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={() => handleClosePopUp()}
        />
      )}
      <ClientDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft />
              </Box>
              <Typography className="header-blue-32">
                {clientData.firstName} {clientData.lastName}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* Print Button */}
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                id={clientDetails?.clientId}
                className="btn-primary edit-button"
                onClick={() => window.print()} // Add this line
              >
                Print
              </Button>
              <Button
                className="details-remove-icon no-hover"
                onClick={handleClickOpen}
              >
                {/* delete icon */}
                <DeleteIcon />
              </Button>
              <Button
                sx={{
                  mt: { xs: 0, md: 0 },
                  mr: 2,
                  border: '2px solid #003087 !important',
                  color: '#003087 !important',
                  padding: '6px 20px !important'
                }}
                variant="outlined"
                className="btn-primary"
                id={clientDetails?.clientId}
                onClick={addAnotherPet}
              >
                Add Another Dog
              </Button>
              {/* Edit Button */}
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                id={clientDetails?.clientId}
                onClick={handleClickEdit}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Vaccination alerts */}
        <Box
          className="location-tab-container"
          marginY="19px"
          display="flex"
          flexDirection="column"
          gap="10px"
        >
          {Array.isArray(getAlertReminder) &&
            getAlertReminder?.length > 0 &&
            getAlertReminder?.map((reminder) => {
              return (
                <CustomAlert
                  title={reminder?.alertName}
                  description={reminder?.alertMessage}
                  backgroundColor={
                    reminder?.alertName === 'Reminder' ? '#FBD6D5' : '#FBD6D5'
                  }
                  icon={<WarningIcon sx={{ color: '#430C00' }} />}
                />
              );
            })}
        </Box>

        {/* Table  */}
        <Box className="location-tab-container" marginY="40px">
          {loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : (
            <DynamicTabs list={tabList || []} />
          )}
        </Box>

        <Box className="location-details-container">
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">LOCATION</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails?.locationName}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">NAME</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails?.firstName} {clientDetails?.lastName}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails?.email || ''}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">MOBILE NUMBER</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails?.mobilePhone}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">ADDRESS</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {[
                  clientDetails?.addressLine1,
                  clientDetails?.addressLine2,
                  clientDetails?.city,
                  clientDetails?.state,
                  clientDetails?.postalCode
                ]
                  ?.filter(Boolean)
                  ?.join(', ')}{' '}
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="location-details-col">
              <Box className="location-details-label">STATUS</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value">
                {clientDetails?.status === 1 && 'Active'}
                {clientDetails?.status === 0 && 'Inactive'}
                {clientDetails?.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid
              item
              xs={12}
              sm={2}
              className="location-details-col d-flex align-items-center"
            >
              <Box className="location-details-label">
                LIABILITY WAIVER SIGNED
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              className="location-details-col d-flex align-items-center"
            >
              <Box
                className="location-details-value location-image"
                sx={{
                  color: `${
                    clientDetails?.isLiabilityWaiverSigned ? '' : 'red'
                  } !important`
                }}
              >
                {clientDetails?.isLiabilityWaiverSigned ? 'Yes' : 'No'}
              </Box>
            </Grid>
            {clientDetails?.isLiabilityWaiverSigned &&
              selectedClientItem?.length > 0 &&
              selectedClientItem[0]?.signatureImage && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="location-details-col d-flex align-items-center"
                  >
                    <Box className="location-details-label">
                      SIGNATURE IMAGE
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} className="location-details-col">
                    <Box
                      className="location-details-value location-image"
                      sx={{
                        height: '108px',
                        width: '100%',
                        border: '2px solid rgba(0, 48, 135, 0.5)',
                        borderRadius: '10px',
                        padding: '15px',
                        float: 'left',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        src={selectedClientItem[0]?.signatureImage || ''}
                        alt="my signature"
                        style={{
                          maxHeight: '100%',
                          maxWidth: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
          </Grid>

          <Grid container mt={3} className="location-details-row">
            <Grid
              item
              xs={12}
              sm={2}
              className="location-details-col d-flex align-items-center"
            >
              <Box className="location-details-label">PROFILE IMAGE</Box>
            </Grid>
            <Grid item xs={12} sm={4} className="location-details-col">
              <Box className="location-details-value location-image">
                <Avatar
                  alt="icon"
                  src={clientDetails?.profileImage || petPlaceholder}
                  // sx={{ marginLeft: 2 }}
                  sx={{ width: '60px', height: '60px' }}
                  className="card-avatar-img"
                />
              </Box>
            </Grid>
          </Grid>

          {/* PET Details */}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="header-blue-22 mt-10 mb-10"
          >
            <span className="location-name text-wrap mt-10">
              Associated Dog
            </span>
          </Grid>
          <Grid container spacing={2}>
            <Grid width={1} item>
              <Card className="p-15">
                {/* <Divider /> */}
                <TableContainer className="franchise-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell sx={{ minWidth: '250px' }}>
                          Dog Name
                        </TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Breed</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(allPets.length > 0 &&
                        allPets.map((pet) => {
                          return (
                            <TableRow
                              hover
                              key={pet.sortKey}
                              // selected={isTeamItemSelected}
                              className="table-row cursor-pointer"
                              onClick={(e) =>
                                handleViewPetDetails(
                                  pet.sortKey,
                                  e,
                                  getClientId,
                                  pet.status
                                )
                              }
                            >
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                  className="d-flex align-items-center"
                                >
                                  <Typography>
                                    <Avatar
                                      alt="icon"
                                      src={pet.profileImage || petPlaceholder}
                                      className="card-avatar-img mr-10"
                                    />
                                  </Typography>
                                  <Typography>
                                    {' '}
                                    {/* {pet.firstName + ' ' + pet.lastName} */}
                                    {pet?.firstName} {pet?.lastName}
                                  </Typography>
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {pet.birthDate
                                    ? calculateAge(pet.birthDate)
                                    : null}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {pet.breed}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <IconButton
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.secondary.lighter
                                    },
                                    color: theme.palette.primary.main,
                                    borderRadius: '50% !important'
                                  }}
                                  color="inherit"
                                  size="medium"
                                  borderRadius="50% !important"
                                  onClick={(e) =>
                                    editPet(pet.sortKey, e, getClientId)
                                  }
                                  disabled={pet.status === 0}
                                >
                                  <EditTwoToneIcon fontSize="medium" />
                                </IconButton>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                >
                                  {pet.status === 1 ? 'Active' : 'Deactive'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <MoreVertIcon
                                  onClick={(e) => openMenu(pet, e)}
                                  color="grey"
                                  variant="contained"
                                  className="three-dots-icon"
                                />

                                <Menu
                                  open={Boolean(anchor)}
                                  anchorEl={anchor}
                                  onClose={closeMenu}
                                  keepMounted
                                  PaperProps={{
                                    style: {
                                      maxHeight: 40 * 5,
                                      width: '25ch'
                                    }
                                  }}
                                >
                                  <MenuItem
                                    onClick={(e) =>
                                      onMenuItemClick(pet, e, 'addAlert')
                                    }
                                    disabled={getPetItem.status === 0}
                                  >
                                    Add Alert & Notes
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) =>
                                      onMenuItemClick(pet, e, 'assignTrophy')
                                    }
                                    disabled={getPetItem.status === 0}
                                  >
                                    Assign Trophy
                                  </MenuItem>
                                  {getPetItem.status === 1 ? (
                                    <MenuItem
                                      onClick={(e) =>
                                        dogStatusHandle(pet, e, 'deactivate')
                                      }
                                    >
                                      Deactivate Dog
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      onClick={(e) =>
                                        dogStatusHandle(pet, e, 'activate')
                                      }
                                    >
                                      Activate Dog
                                    </MenuItem>
                                  )}
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })) || <Box className="p-15">No dogs found</Box>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>

          {showError && (
            <Grid container mt={3} className="location-details-row">
              <Box className="error-message">{errorMessage}</Box>
            </Grid>
          )}
        </Box>
      </ClientDetailsWrapper>
      {isAddTeamOpen && (
        <TrophyAssign
          openAddteam={openAddteam}
          setopenAddteam={setopenAddteam}
          setIsAddTeamOpen={setIsAddTeamOpen}
          getPetItem={getPetItem}
          getEventToRefresh={getEventToRefresh}
        />
      )}

      {/* Add alert and notes */}
      <Box>
        {openAlertNotes && (
          <AddAlertNotes
            openAlertNotesDialog={openAlertNotesDialog}
            setOpenAlertNotesDialog={setOpenAlertNotesDialog}
            setOpenAlertNotes={setOpenAlertNotes}
            // getEventToRefresh={getEventToRefresh}
            refreshAlertNotes={refreshAlertNotes}
            getClientItem={getPetItem}
            type={'pet'}
          />
        )}
      </Box>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMessageDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDelete('message-close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected client has been deactivated successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDelete('message-close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPet}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handlePetClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {getPetItem.status === 1 ? 'Deactivate' : 'Activate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to{' '}
                {getPetItem.status === 1 ? 'deactivate' : 'activate'} this dog?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handlePetClose('Delete')}
          >
            {getPetItem.status === 1 ? 'Deactivate' : 'Activate'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handlePetClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSchedulePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleOkClick('close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Deactivation of this dog is not possible due to existing
                schedules.
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleOkClick('ok')}
          >
            Ok
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleOkClick('close')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientDetails;
