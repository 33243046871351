import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import moment from 'moment';

export const UpcomingScheduleTable = (props) => {
  const {
    tableRows,
    searchQuery
  } = props;

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  
  useEffect(() => {
    setPage(0);
  }, [tableRows]);
  
  const dateFormate = (date) => {
    let utc;
    utc = (date) ? moment?.utc(date)?.format('MMM D, YYYY, h:mm A') : '';
    return utc;
  }


  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Date and Time</TableCell>
                <TableCell>Schedule Name</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell>Trainer</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData && paginatedData.length > 0 && paginatedData.map((row, i) => {
                
                return (
                  <TableRow
                    hover
                    key={i}
                    role="checkbox"
                    tabIndex={-1}
                    className="table-row cursor-pointer"
                  >
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        // sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row?.fromDate && dateFormate(row?.fromDate?.replaceAll('-','/'))}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.serviceName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.clientDetails && row?.clientDetails?.length > 0 && row?.clientDetails[0]?.clientChildName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.trainerName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography

                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
