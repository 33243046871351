/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { Box, CircularProgress, Grid, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {
  CreateOrder,
  PurchaseFilters,
  PurchaseTable,
  Summary,
  OrderReceipt
} from '../../../components/Purchase/index';
import { CustomButton, SearchInput } from 'src/components/Global';
import GlobalData from 'src/global';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { getLocalData, filtersQuery } from 'src/utils';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from '../../../components/PopupMessage/SuccessMessage';
import Header from '../../../components/Header';
import { ApiService } from 'src/services/ApiService';

const Purchase = () => {
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Purchase Orders');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Purchase Orders';
  const title = GlobalData[0].title;

  const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;

  const navigate = useNavigate();
  let userId = getLocalData('userId');
  let userID = localStorage.getItem('userId');
  const userEmail = localStorage.getItem('userEmail');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [allPurchaseData, setAllPurchaseData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [allInventoryData, setAllInventoryData] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [purchaseStatus, setPurchaseStatus] = useState('create');
  const locationId = getLocalData('locationId');
  const locationName = getLocalData('locationName');
  const franchiseeId = getLocalData('franchiseeId');
  const [quantity, setQuantity] = useState({});
  const [prevQuantity, setPrevQuantity] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  console.log(selectedRowsData, 'selectedRowsDataselectedRowsData');
  const [editOrder, setEditOrder] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isToggleOne, setIsToggleOne] = useState(true);
  const [isToggleTwo, setIsToggleTwo] = useState(true);
  const [unitCost, setUnitCost] = useState({});
  const [shippingCost, setShippingCost] = useState(0);
  const [itemPrice, setItemPrice] = useState({});
  const [prevCost, setPrevCost] = useState({});
  const [allLocationsList, setAllLocationsList] = useState([]);

  const [isFilters, setIsFilters] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
    orderTitle: '',
    supplierName: '',
    orderStatus: -1
  });

  const [inventoryFilters, setInventoryFilters] = useState({
    isBestSeller: null,
    manufacturer: null,
    categoryName: null
  });

  const [orderDetails, setOrderDetails] = useState({
    number: generateRandomOrderNumber(),
    title: '',
    supplier: '',
    notes: '',
    address: '',
    isDefault: true,
    dateCreated: '',
    status: '',
    orderStatus: '',
    purchaseOrderId: '',
    shippingCost: 0.0,
    subTotal: 0.0,
    total: 0.0,
    items: []
  });

  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });

  const [endNotes, setEndNotes] = useState({
    shareSelfMail: true,
    shareSupplierMail: true,
    notes: '',
    title: '',
    nummber: ''
  });

  useEffect(() => {
    getLocationData();
  }, []);

  const handleEndDetails = (type, value) => {
    if (type === 'shareSelfMail') {
      setIsToggleOne(!isToggleOne);
    }
    if (type === 'shareSupplierMail') {
      setIsToggleTwo(!isToggleTwo);
    }
    setEndNotes({ ...endNotes, [type]: value });
    console.log(endNotes);
  };

  function generateRandomOrderNumber() {
    // Generate a random order number
    const randomNumber = Math.floor(Math.random() * 1000000) + 1;
    return `#${randomNumber}`;
  }

  function defaultQuantity() {
    const defaultQuantities = {};
    allInventoryData.forEach((item) => {
      defaultQuantities[item.sortKey] = 0;
      console.log(item);
    });

    return defaultQuantities;
  }

  function initialUnitCost() {
    const initialCost = {};
    allInventoryData.forEach((item) => {
      initialCost[item.sortKey] = item.cost || 0;
      console.log('initialCost :', item.cost);
    });
    // console.log("initialCost :" ,item.cost)
    return initialCost;
  }

  function initialItemPrice() {
    const initialPrice = {};
    allInventoryData.forEach((item) => {
      initialPrice[item.sortKey] = item.price;
      console.log('initialPrice :', item.price);
    });
    // console.log("initialCost :" ,item.cost)
    return initialPrice;
  }

  useEffect(() => {
    if (purchaseStatus !== 'receive' && purchaseStatus !== 'revise') {
      setQuantity(defaultQuantity());
      setUnitCost(initialUnitCost());
    }
    // setUnitCost(initialUnitCost());
    setItemPrice(initialItemPrice());
  }, [allInventoryData]);

  // useEffect(() => {
  //   setShippingCost(orderDetails.shippingCost);
  // }, [orderDetails.shippingCost]);

  // const calculateTotalPrice = () => {
  //   const totalPrice = selectedRowsData?.reduce((total, obj) => {
  //     const price = parseFloat(obj.price) || 0;
  //     return total + price;
  //   }, 0);

  //   return totalPrice?.toFixed(2);
  // };
  const calculateTotalPrice = () => {
    const totalPrice = selectedRowsData?.reduce((total, obj) => {
      const cost = unitCost[obj.sortKey] || 0;
      // console.log(obj, "obj", cost);
      const price = parseFloat(obj.price) || 0;
      // const quan = quantity[obj.id] || 0;
      const quan = quantity[obj.sortKey] || 0;
      // return total + price * quan;
      return cost > 0 ? total + cost * quan : total + price * quan;
    }, 0);
    return totalPrice?.toFixed(2);
  };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  const getData = async () => {
    setLoading(true);
    try {
      const encodedLocationId = encodeURIComponent(locationId);

      const inventoryPromise = ApiService.get(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1&isDiscontinued=false`
      );

      const suppliersPromise = ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&businessEntityType=Supplier`
      );

      const purchasePromise = ApiService.get(
        `${BASE_URL}purchase-orders?locationId=${encodedLocationId}`
      );

      const categoryPromise = ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}products/getAllProductCategories?locationId=${encodedLocationId}`
      );

      const [
        inventoryResponse,
        suppliersResponse,
        purchaseResponse,
        categoryResponse
      ] = await Promise.all([
        inventoryPromise,
        suppliersPromise,
        purchasePromise,
        categoryPromise
      ]);

      const inventoryData = inventoryResponse;
      const suppliersData = suppliersResponse;
      const purchaseData = purchaseResponse;
      const categoryData = categoryResponse;

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item?.PRDSort
          })
        );
        setAllInventoryData(modifiedInventoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          inventoryData?.message || 'Something went wrong with inventory'
        );
      }

      if (!suppliersData?.error) {
        const modifiedSuppliersData = suppliersData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item?.sortKey
          })
        );
        setAllSuppliers(modifiedSuppliersData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          suppliersData?.message || 'Something went wrong with suppliers list'
        );
      }

      if (!purchaseData?.error) {
        const modifiedPurchaseData = purchaseData?.data?.Items?.map((item) => ({
          ...item,
          id: item?.PRDSort
        }));
        setAllPurchaseData(modifiedPurchaseData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          purchaseData?.message || 'Something went wrong with purchase'
        );
      }

      if (!categoryData?.error) {
        const modifiedCategoryData = categoryData?.data?.Items?.map((item) => ({
          ...item,
          id: item?.categoryId
        }));
        setCategoryData(modifiedCategoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoryData?.message || 'Something went wrong with purchase'
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong with fetching data');
    }
  };

  const getFilteredPurchase = async () => {
    let queryString = filtersQuery(filters);
    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const purchaseData = await ApiService.get(
        `${BASE_URL}purchase-orders?locationId=${encodedLocationId}&${queryString}`
      );

      if (!purchaseData?.error) {
        const modifiedPurchaseData = purchaseData?.data?.Items?.map((item) => ({
          ...item,
          id: item?.PRDSort
        }));
        setPurchaseData(modifiedPurchaseData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          purchaseData?.message || 'Something went wrong with purchase'
        );
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const getFilteredInventory = async () => {
    let queryString = await filtersQuery(inventoryFilters);

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const inventoryData = await ApiService.get(
        `${BASE_URL}inventory?locationId=${encodedLocationId}&status=1&isDiscontinued=false&${queryString}`
      );

      if (!inventoryData.error) {
        const modifiedInventoryData = inventoryData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.PRDSort
          })
        );
        setInventoryData(modifiedInventoryData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          inventoryData?.message || 'Something went wrong with inventory'
        );
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getFilteredPurchase();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    getFilteredInventory();
  }, [JSON.stringify(inventoryFilters)]);

  useEffect(() => {
    const data = allInventoryData?.filter((item) =>
      selectedRows?.includes?.(item.sortKey)
    );
    console.log('quantity data: ', data);
    setSelectedRowsData(data);
  }, [selectedRows]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  function findObjectById(id) {
    return purchaseData?.find((obj) => obj.PRDSort == id);
  }

  const handleOrderSummary = (type, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (type == 'duplicate') {
      setOrderDetails({ ...orderDetails, number: generateRandomOrderNumber() });
      setPurchaseStatus('create');
    } else if (type == 'revise') {
      // setPurchaseStatus('revise');
      // setInventoryData(selectedRowsData);
      setEditOrder('edit');
      setPurchaseStatus('create');
      setOrderDetails({ ...orderDetails });
    } else if (type == 'receive') {
      setPurchaseStatus('receive');
      setInventoryData(selectedRowsData);
    }

    handleOpenScreen(2);
  };

  const handleEditOrder = (id, type, event) => {
    event.preventDefault();
    event.stopPropagation();
    let item = findObjectById(id);
    setEditOrder('edit');
    console.log('item', item);

    if (item) {
      setPurchaseStatus('create');
      if (item.items?.length) setSelectedProductRows(item.items);
      setOrderDetails({
        number: item?.orderNumber,
        title: item?.orderTitle,
        supplier: item?.supplierId,
        notes: item?.notes,
        address: item?.shippingAddress,
        isDefault: item?.defaultShippingAddress,
        dateCreated: item?.createdAt,
        status: item?.status,
        orderStatus: item?.orderStatus,
        purchaseOrderId: item?.purchaseOrderId,
        shippingCost: item?.shippingCost,
        subTotal: item?.subTotal,
        total: item?.total,
        items: item?.items
      });

      handleOpenScreen(2);
    }
    // handleOpenScreen(2);
  };

  const openSummary = (id, event) => {
    event.preventDefault();
    event.stopPropagation();

    let item = findObjectById(id);
    console.log('item summary', item);
    if (item) {
      setPurchaseStatus('summary');
      setOrderDetails({
        number: item.orderNumber,
        title: item.orderTitle,
        supplier: item.supplierId,
        notes: item.notes,
        address: item.shippingAddress,
        isDefault: item.defaultShippingAddress,
        dateCreated: item.createdAt,
        status: item.status,
        orderStatus: item.orderStatus,
        purchaseOrderId: item.PRDSort,
        shippingCost: item?.shippingCost,
        subTotal: item?.subTotal,
        total: item?.total,
        items: item?.items
      });

      if (setSelectedProductRows(item.items)) {
        handleOpenScreen(3);
        // setSelectedRowsData(item.items)
      } else {
        setShowErrorMessage(true);
        setErrorMessage('Missing some values in response');
        // toast.error('Missing some values in response');
      }
    } else {
      setShowErrorMessage(true);
      setErrorMessage('Missing some values in response');
      // toast.error('Missing some values in response');
    }
  };

  function setSelectedProductRows(array) {
    console.log(array, 'arrrrrrrrrrrrr');
    if (!Array.isArray(array) || !array.length) {
      return false;
    }
    const idsToUpdate = array?.map(({ itemId }) => itemId);
    console.log('idsToUpdate', idsToUpdate);
    const updatedStateObject = { ...quantity };
    const updatedUnitCost = { ...unitCost };

    console.log('updatedStateObject', updatedStateObject, updatedUnitCost);
    idsToUpdate.forEach((id) => {
      const matchingItem = array.find((item) => item.itemId == id);
      if (
        matchingItem &&
        Object.prototype.hasOwnProperty.call(updatedStateObject, id) &&
        Object.prototype.hasOwnProperty.call(updatedUnitCost, id)
      ) {
        updatedStateObject[id] = matchingItem.quantity;
        updatedUnitCost[id] = matchingItem.cost;
      }
    });

    //Hell
    const data = inventoryData?.filter((item) =>
      idsToUpdate?.includes?.(item.sortKey)
    );

    // console.log('data rows', data);
    // setSelectedRowsData(data);
    //end

    setSelectedRows(idsToUpdate);
    setQuantity(updatedStateObject);
    setPrevQuantity(updatedStateObject);
    setUnitCost(updatedUnitCost);
    setPrevCost(updatedUnitCost);

    return true;
  }
  console.log(quantity, 'quantity isdac');

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setPurchaseStatus('create');
    setIsFilters(false);
    setEditOrder('');
    setQuantity(defaultQuantity());
    setPrevQuantity({});
    setSelectedRows([]);
    setSelectedRowsData([]);
    setPrevCost({});

    setDialog({
      state: false,
      isCreated: false
    });
    setShippingCost(0);

    setTimeout(() => {
      setOrderDetails({
        number: generateRandomOrderNumber(),
        title: '',
        supplier: '',
        notes: '',
        address: '',
        dateCreated: '',
        isDefault: true,
        status: '',
        orderStatus: '',
        purchaseOrderId: '',
        shippingCost: 0.0,
        subTotal: 0.0,
        total: 0.0,
        items: []
      });

      setEndNotes({
        shareSelfMail: true,
        shareSupplierMail: true,
        notes: '',
        title: '',
        nummber: ''
      });
    }, 500);

    setFilters({
      status: null,
      orderTitle: null,
      supplierName: null
    });

    //call  api for getting latest data of services
    setLoading(true);
    setTimeout(() => {
      getData();
      getFilteredPurchase();
      getFilteredInventory();
    }, 1000);
  };

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  function findSupplierName(id) {
    const foundSupplier = allSuppliers?.find(
      // (supplier) => supplier.supplierId == id
      (supplier) => supplier.sortKey == id
    );

    if (foundSupplier) {
      // return foundSupplier.supplierName;
      return foundSupplier.businessName;
    }

    return ''; // Return an empty string if no supplier with the given id is found
  }

  const postData = async (status) => {
    setBtnLoading(true);
    let idss = [];
    let itemsData = [];

    let orderStatus = '';

    if (status == 'Draft') {
      orderStatus = 'draft';
      setLoading(true);
    } else if (status == 'Arrived') {
      orderStatus = 'send';
    }

    selectedRowsData?.map((item) => {
      // const quantityValue = quantity[item.PRDSort]; // Get the corresponding quantity for the item
      // const unitCostValue = unitCost[item.PRDSort];
      // console.log(unitCostValue, "unitCostValue");
      // idss.push({ itemId: item.PRDSort });
      // itemsData.push({
      //   itemId: item.PRDSort,
      //   quantity: quantityValue,
      //   category: item?.category?.categoryName || '',
      //   itemName: item?.productName,
      //   unitCost: unitCostValue
      // });
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      const unitCostValue = unitCost[item.sortKey];
      const defaultCost = item?.price;
      //  console.log(unitCostValue, "unitCostValue");
      idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue,
        category: item?.categoryName || '',
        // itemVariantType: item?.variant?.variantType || '',
        // itemVariantValue: item?.variant?.value || '',
        itemName: item?.fName,
        variants: item?.variants ?? [],
        cost: (unitCostValue && unitCostValue) || (defaultCost && defaultCost),
        amount:
          (unitCostValue && unitCostValue * quantityValue) ||
          (defaultCost && defaultCost * quantityValue) ||
          0
      });

      return null;
    });

    let shipCost = Number(shippingCost) || 0;
    let totalAmount = Number(calculateTotalPrice()) + Number(shipCost);

    const data = {
      locationId: locationId,
      locationName: locationName,
      createdBy: userID,
      supplierId: orderDetails.supplier,
      orderStatus: orderStatus,
      supplierName: findSupplierName(orderDetails.supplier),
      orderTitle: orderDetails.title,
      notes: orderDetails.notes,
      defaultShippingAddress: orderDetails.isDefault,
      shippingAddress: orderDetails.address,
      items: itemsData,
      itemIds: idss,
      subTotal: calculateTotalPrice(),
      shippingCost: shipCost,
      total: totalAmount,
      // sendMailToSupplier: endNotes.shareSelfMail, // this is not required in API for create
      // sendMailToFranchiseeOwner: endNotes.shareSupplierMail, // this is not required in API for create
      orderNumber: orderDetails.number // orderNumber is required in API for create
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const responseData = await ApiService.post(
        `${BASE_URL}purchase-orders`,
        data
      );

      if (!responseData.error) {
        setShowSuccessMessage(true);
        setSuccessMessage('Purchase order has been created successfully!');
        setShippingCost(0);
        // update the order details with the new order id
        setOrderDetails({
          ...orderDetails,
          purchaseOrderId: responseData?.data
        });

        setEndNotes({
          ...endNotes,
          title: orderDetails?.title,
          nummber: orderDetails?.number
        });

        if (
          status == 'Draft' ||
          purchaseStatus == 'revise' ||
          purchaseStatus == 'receive'
        ) {
          handleResetStates();
        } else if (status == 'Arrived') {
          getData();
          handleOpenScreen(4);
        }
        setBtnLoading(false);
        setLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          responseData?.message ||
            'Something went wrong with add purchase order'
        );
        setBtnLoading(false);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      setLoading(false);
      // toast.error('Something went wrong, Try again!');
    }
  };

  //function for revise order
  const postReviseData = async (status) => {
    setBtnLoading(true);
    let itemsData = [];

    let orderStatus = '';
    if (status == 'Draft') {
      orderStatus = 'draft';
      setLoading(true);
    } else if (status == 'Arrived') {
      orderStatus = 'send';
    }

    selectedRowsData?.map((item) => {
      // const quantityValue = quantity[item.PRDSort]; // Get the corresponding quantity for the item
      // itemsData.push({
      //   itemId: item.PRDSort,
      //   quantity: quantityValue,
      //   category: item?.category?.categoryName,
      //   itemName: item?.productName
      // });
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      const unitCostValue = unitCost[item.sortKey];
      const defaultCost = item?.price;
      // idss.push({ itemId: item.sortKey });
      itemsData.push({
        itemId: item.sortKey,
        quantity: quantityValue,
        category: item?.categoryName || '',
        // itemVariantType: item?.variant?.variantType || '',
        // itemVariantValue: item?.variant?.value || '',
        itemName: item?.fName,
        variants: item?.variants ?? [],
        cost: (unitCostValue && unitCostValue) || (defaultCost && defaultCost),
        amount:
          (unitCostValue && unitCostValue * quantityValue) ||
          (defaultCost && defaultCost * quantityValue)
      });

      return null;
    });

    let poId = orderDetails.purchaseOrderId;
    if (poId && !poId.includes('#PO#') && poId !== 'undefined') {
      poId = '#PO#' + poId;
    }
    let shipCost = Number(shippingCost) || 0;
    let totalAmount = Number(calculateTotalPrice()) + Number(shipCost);

    const data = {
      locationId: locationId,
      // purchaseOrderId: orderDetails.purchaseOrderId,
      purchaseOrderId: poId,
      items: itemsData,
      status: orderStatus,
      subTotal: calculateTotalPrice(),
      shippingCost: shipCost,
      total: totalAmount,
      notes: orderDetails?.notes || '',
      shippingAddress: orderDetails?.address || '',
      supplierId: orderDetails?.supplier,
      supplierName: findSupplierName(orderDetails?.supplier)
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const responseData = await ApiService.post(
        `${BASE_URL}purchase-orders/updatePurchaseOrder`,
        data
      );

      if (!responseData.error) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          'Purchase order draft has been updated successfully!'
        );
        setEndNotes({
          ...endNotes,
          title: orderDetails?.title,
          nummber: orderDetails?.number
        });

        if (
          status == 'Draft' ||
          purchaseStatus == 'revise' ||
          purchaseStatus == 'receive'
        ) {
          handleResetStates();
        } else if (status == 'Arrived') {
          getData();
          handleOpenScreen(4);
        }
        setBtnLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          responseData?.message ||
            'Something went wrong with revise purchase order'
        );
        setBtnLoading(false);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      setLoading(false);
      // toast.error('Something went wrong, Try again!');
    }
  };

  console.log(orderDetails, 'orderDetailsorderDetailsorderDetailsorderDetails');

  //function for receive order
  const postReceiveData = async (status) => {
    setBtnLoading(true);
    let itemsData = [];

    selectedRowsData?.map((item) => {
      const quantityValue = quantity[item.sortKey]; // Get the corresponding quantity for the item
      const unitCostValue = unitCost[item.sortKey];
      const defaultCost = item?.price;

      itemsData.push({
        itemId: item.sortKey,
        // itemVariantType: item?.variant?.variantType || '',
        // itemVariantValue: item?.variant?.value || '',
        quantity: quantityValue,
        category: item?.categoryName || '',
        itemName: item?.fName,
        variants: item?.variants ?? [],
        cost: (unitCostValue && unitCostValue) || (defaultCost && defaultCost),
        amount:
          (unitCostValue && unitCostValue * quantityValue) ??
          (defaultCost && defaultCost * quantityValue) ??
          0
      });

      return null;
    });

    console.log('itemsData', itemsData);

    let poId = orderDetails.purchaseOrderId;
    if (poId && !poId.includes('#PO#') && poId !== 'undefined') {
      poId = '#PO#' + poId;
    }

    let shipCost =
      Number(shippingCost) || Number(orderDetails?.shippingCost) || 0;
    let totalAmount = Number(calculateTotalPrice()) + Number(shipCost);

    const data = {
      locationId: locationId,
      // purchaseOrderId: orderDetails.purchaseOrderId,
      purchaseOrderId: poId,
      items: itemsData,
      subTotal: calculateTotalPrice(),
      shippingCost: shipCost,
      total: totalAmount,
      supplierId: orderDetails?.supplier,
      supplierName: findSupplierName(orderDetails.supplier),
      notes: orderDetails?.notes
    };
    let url;
    if (status == 'Draft') {
      url = `${BASE_URL}purchase-orders/updatePurchaseOrder`;
    } else {
      url = `${BASE_URL}purchase-orders/receivePurchaseOrder`;
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    try {
      const responseData = await ApiService.post(
        `${BASE_URL}purchase-orders/receivePurchaseOrder`,
        data
      );

      if (!responseData?.error) {
        setShowSuccessMessage(true);
        setSuccessMessage('Purchase order has been received successfully!');

        handleResetStates();
        setBtnLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          responseData?.message ||
            'Something went wrong with receive purchase order'
        );
        setBtnLoading(false);
      }
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };
  const handleManageClick = () => {
    setShippingCost(0);
    handleOpenScreen(2);
  };
  const buttons = [
    {
      label: 'Create New Order',
      onClick: handleManageClick,
      theam: true
    }
    // VV removed below as not needed
    /*
      const handleButtonClick = () => {
        navigate('/manage-suppliers')
      };
      {
        label: ' Manage Suppliers',
        onClick: handleButtonClick,
        theam:true
      },*/
  ];

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  const getLocationData = async () => {
    try {
      let encodedFranchiseeId = encodeURIComponent(franchiseeId);

      const locationsData = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`
      );

      if (!locationsData?.error) {
        const modifiedInventoryData = locationsData?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item?.sortKey
          })
        );
        setAllLocationsList(modifiedInventoryData);
      } else {
        setErrorMessage(
          locationsData?.message || 'Something went wrong with locations'
        );
      }
    } catch (error) {
      setErrorMessage('Internal Server Error');
    }
  };

  console.log(selectedRowsData, 'setSelectedRowsData');

  return (
    <>
      <Helmet>
        <title>Purchase Orders | {title}</title>
      </Helmet>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Container maxWidth="100%" className="custom-container pb-40">
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <CreateOrder
                    handleResetStates={handleResetStates}
                    loading={loading}
                    postData={postData}
                    dialog={dialog}
                    setDialog={setDialog}
                    purchaseStatus={purchaseStatus}
                    inventoryData={inventoryData}
                    allInventoryData={allInventoryData}
                    allSuppliers={allSuppliers}
                    filters={inventoryFilters}
                    setFilters={setInventoryFilters}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    prevQuantity={prevQuantity}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setSelectedRowsData={setSelectedRowsData}
                    orderDetails={orderDetails}
                    setOrderDetails={setOrderDetails}
                    categoryData={categoryData}
                    handleOpenScreen={handleOpenScreen}
                    editOrder={editOrder}
                    postReviseData={postReviseData}
                    unitCost={unitCost}
                    setUnitCost={setUnitCost}
                    itemPrice={itemPrice}
                    setItemPrice={setItemPrice}
                    prevCost={prevCost}
                    allLocationsList={allLocationsList}
                  />
                </Box>
              );

            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  <Summary
                    dialog={dialog}
                    setDialog={setDialog}
                    quantity={quantity}
                    orderDetails={orderDetails}
                    allSuppliers={allSuppliers}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    selectedRowsData={selectedRowsData}
                    handleResetStates={handleResetStates}
                    postData={postData}
                    postReviseData={postReviseData}
                    postReceiveData={postReceiveData}
                    btnLoading={btnLoading}
                    subTotal={calculateTotalPrice()} // orderDetails?.subTotal ||
                    purchaseStatus={purchaseStatus}
                    handleOrderSummary={handleOrderSummary}
                    handleOpenScreen={handleOpenScreen}
                    editOrder={editOrder}
                    unitCost={unitCost}
                    shippingCost={shippingCost}
                    setShippingCost={setShippingCost}
                  />
                </Box>
              );

            case 4:
              return (
                <Box justifyContent="center" direction="column">
                  <OrderReceipt
                    endNotes={endNotes}
                    dialog={dialog}
                    userEmail={userEmail}
                    allSuppliers={allSuppliers}
                    orderDetails={orderDetails}
                    handleResetStates={handleResetStates}
                    handleEndDetails={handleEndDetails}
                    handleOpenScreen={handleOpenScreen}
                    isToggleOne={isToggleOne}
                    isToggleTwo={isToggleTwo}
                    setShowErrorMessage={setShowErrorMessage}
                    setErrorMessage={setErrorMessage}
                    setShowSuccessMessage={setShowSuccessMessage}
                    setSuccessMessage={setSuccessMessage}
                  />
                </Box>
              );

            default:
              return (
                <Box justifyContent="center" direction="column">
                  {showScreen === 1 && (
                    <Header title="Purchase Orders" buttons={buttons} />
                  )}
                  {/* <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    gap="16px"
                  >
                    <Grid item>
                      <CustomButton
                        text="Add New"
                        variant="contained"
                        onClick={() => handleOpenScreen(2)}
                      >
                        Create New Order
                      </CustomButton>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        variant="outlined"
                        onClick={() => navigate('/manage-suppliers')}
                      >
                        Manage Suppliers
                      </CustomButton>
                    </Grid>
                  </Grid> */}

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                    mt={2}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px">
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                              // variant={
                              //   isAnyFilterApplied()
                              //     ? 'contained'
                              //     : isFilters
                              //       ? 'contained'
                              //       : 'outlined'
                              // }
                            >
                              Filters
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            marginT={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <PurchaseFilters
                            filters={filters}
                            setFilters={setFilters}
                            allPurchaseData={allPurchaseData}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <PurchaseTable
                          tableRows={purchaseData}
                          searchQuery={searchQuery}
                          openSummary={openSummary}
                          handleEditOrder={handleEditOrder}
                        />
                      </Grid>
                    </>
                    {/* )} */}
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default Purchase;
