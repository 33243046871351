import React, { useState, useEffect } from 'react';
// import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Slide,
  Autocomplete,
  TextField
} from '@mui/material';
import { CustomSelect, CustomTextField, CustomButton } from '../Global';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getLocalData } from 'src/utils';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import Loader from '../Loader';
import { formatTimeOrDate, getValueFromId } from './Const';
import { ApiService } from '../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function BookClassDialog(props) {
  const {
    getEnrollmentDetailData,
    dialog,
    setDialog,
    onClose,
    setLoadData,
    newSchedule,
    bookClass,
    setBookClass,
    isBookClassEdit,
    allParents,
    allAttendies,
    initials,
    setNewSchedule,
    showScreen,
    setFetchTrigger,
    setAllAttendies
  } = props;
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const userName = getLocalData('UserName');
  const encodedLocationId = encodeURIComponent(locationId);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [allPets, setAllPets] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [openPaylaterPopUp, setOpenPaylaterPopUp] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [waitingList, setWaitingList] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [waitingNumber, setWaitingNumber] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [maxWaitList, setMaxWaitList] = useState(false);
  const [totalCredits, setTotalCredits] = useState(0);
  const [showCredit, setShowCredit] = useState(false);
  const [noOfCredit, setnoOfCredit] = useState([]);
  const [sufficiantCredits, setSufficiantCredits] = useState(false);
  // const [isCreditApplied, setIsCreditApplied] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [prerequisites, setPrerequisites] = useState('');
  const [trophys, setTrophys] = useState([]);
  const [trophyExist, setTrophyExist] = useState(true);
  const [parents, setParents] = useState(null);
  const [petExist, setPetExist] = useState(false);
  const [vaccinationDetails, setVaccinationDetails] = useState({
    bordetellaAlert: '',
    dhppExpiration: '',
    rabiesAlert: ''
  });

  const [openVaccinationDialog, setOpenVaccinationDialog] = useState(false);
  const [creditClientId, setCreditClientId] = useState('')

  // const [serviceData, setServiceData] = useState([]);
  const [closeDialog, setCloseDialog] = useState({
    state: false,
    isCreated: false,
    creditApplied: false,
    insufficientCredits: false
  });
  const getClientData = (data) => {
    const excludedStatuses = ['earlyCancel', 'lateCancel', 'absent'];
    let count = data.filter((item) => !excludedStatuses.includes(item.status));
    return count;
  };
  useEffect(() => {
    setPetExist(false);
    let Attendies = getClientData(allAttendies);
    Attendies?.forEach((item) => {
      if (item.clientChildId === bookClass.pets) {
        setPetExist(true);
      }
    });
  }, [bookClass.pets]);
  const [isError, setIsError] = useState({
    // status: false,
    // appointmentStatus: false,
    fromDate: false,
    toDate: false,
    fromTime: false,
    toTime: false,
    fromTimeFormat: false,
    toTimeFormat: false,
    occurence: false,
    description: false,
    parent: false,
    pets: false,
    intervalDays: false
  });

  useEffect(() => {
    if (
      bookClass.parent &&
      bookClass.pets &&
      bookClass.serviceId &&
      bookClass.categoryId
    ) {
      checkCredit();
    }
  }, [bookClass.parent, bookClass.pets]);
  useEffect(() => {
    if (
      bookClass?.parent &&
      bookClass?.pets &&
      bookClass?.fromDate &&
      bookClass?.toDate
    ) {
      let petData = allPets?.find((item) => item?.sortKey === bookClass?.pets);
      let scheduleDate = new Date(bookClass?.fromDate);
      let scheduleToDate = new Date(bookClass?.toDate);
      let brdelaexp = petData?.bordetellaExpiration?.replaceAll('-', '/');
      let rbexp = petData?.rabiesExpiration?.replaceAll('-', '/');
      let dhppexp = petData?.dhppExpiration?.replaceAll('-', '/');
      let bordetellaExpiration = new Date(brdelaexp);
      let rabiesExpiration = new Date(rbexp);
      let dhppExpiration = new Date(dhppexp);
      let newVaccinationDetails = {
        bordetellaAlert: '',
        rabiesAlert: '',
        dhppExpiration: ''
      };

      if (bookClass.occurence === 'noRepeat') {
        if (scheduleDate > bordetellaExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.bordetellaAlert = `Bordetella vaccination expired on ${petData.bordetellaExpiration}.`;
        }
        if (scheduleDate > rabiesExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.rabiesAlert = `Rabies vaccination expired on ${petData.rabiesExpiration}.`;
        }
        if (scheduleDate > dhppExpiration) {
          setOpenVaccinationDialog(true);
          newVaccinationDetails.dhppExpiration = `Combo Shot vaccination expired on ${petData.dhppExpiration}.`;
        }
        setVaccinationDetails(newVaccinationDetails);
      } else {
        // Check both fromDate and toDate for other occurrences
        if (
          (bordetellaExpiration >= scheduleDate ||
            bordetellaExpiration <= scheduleDate) &&
          bordetellaExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.bordetellaAlert = `Bordetella vaccination expires on ${petData.bordetellaExpiration}.`;
          setOpenVaccinationDialog(true);
        }
        if (
          (rabiesExpiration >= scheduleDate ||
            rabiesExpiration <= scheduleDate) &&
          rabiesExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.rabiesAlert = `Rabies vaccination expires on ${petData.rabiesExpiration}`;
          setOpenVaccinationDialog(true);
        }
        if (
          (dhppExpiration >= scheduleDate || dhppExpiration <= scheduleDate) &&
          dhppExpiration < scheduleToDate &&
          scheduleDate !== scheduleToDate
        ) {
          newVaccinationDetails.dhppExpiration = `Combo Shot vaccination expires on ${petData.dhppExpiration}`;
          setOpenVaccinationDialog(true);
        }
      }

      setVaccinationDetails(newVaccinationDetails);
    }
  }, [bookClass.parent, bookClass.pets, bookClass.fromDate, bookClass.toDate]);

  const handleCloseConfirm = () => {
    setOpenVaccinationDialog(false);
  };

  const checkCredit = async () => {
    let occurrence = {
      freq: bookClass?.occurence,
      wkst:
        bookClass && bookClass?.intervalDays && bookClass?.intervalDays[0]
          ? 'su'
          : 'su',
      interval: bookClass?.intervalValue ?? 1
      // "byweekday": newSchedule?.intervalDays,
    };

    if (bookClass?.occurence === 'daily') {
      occurrence['byweekday'] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
    } else if (bookClass?.occurence === 'weekly') {
      occurrence['byweekday'] = bookClass?.intervalDays.map((data) => {
        let wdays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];
        return wdays[data.weekday];
      });
    } else {
      occurrence['byweekday'] = bookClass?.intervalDays;
    }

    let data = {
      clientId: bookClass?.parent,
      locationId: locationId,
      petId: bookClass?.pets,
      fromDate: bookClass?.fromDate?.replaceAll('/', '-'),
      toDate: bookClass?.toDate?.replaceAll('/', '-'),
      categoryId: bookClass?.categoryId,
      serviceId: bookClass?.serviceId
    };
    if (bookClass.occurence === 'noRepeat') {
      data.repeat = 'noRepeat';
    }
    if (bookClass.occurence === 'daily') {
      data.repeat = 'daily';
      data.staffId = bookClass.trainerId;
      data.roomId = bookClass.roomId;
      data.occurrence = occurrence;
    }
    if (bookClass.occurence === 'weekly') {
      data.repeat = 'weekly';
      data.staffId = bookClass.trainerId;
      data.roomId = bookClass.roomId;
      data.occurrence = occurrence;
    }
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getAvailableCredits`,
        data
      );

      // Now, compare the lengths and open the dialog if necessary
      if (response.statusCode && !response.error) {
        const availableItems = response?.data?.Items?.filter(
          (items) => items?.remainingSession > 0
        );
        // setnoOfCredit(response?.data?.Items);
        setnoOfCredit(availableItems);
        setTotalCredits(response?.data?.totalRemainingSession);
        setSufficiantCredits(response?.data?.isSufficiantCredits);
        if(availableItems?.[0]?.clientId){
          setCreditClientId(availableItems?.[0]?.clientId)
        }
        setShowCredit(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   if (noOfCredit?.length > 0) {
  //     setTotalCredits(noOfCredit[0]?.remainingSession);
  //   } else {
  //     setTotalCredits(0);
  //   }
  //   // setShowCredit(true)
  // }, [noOfCredit]);
  const handleOnClose = () => {
    setCloseDialog({
      isCreated: false,
      state: false,
      creditApplied: false,
      insufficientCredits: false
    });
    setBookClass({ ...bookClass, payLater: false });
    onClose('bookClass');
    setParents(null);
    setTotalCredits(0);
    setTrophyExist(true);
  };
  const handleCloseScreen = () => {
    if (showScreen !== 3) {
      setNewSchedule(initials);
    }
    setTrophyExist(true);
    setWaitingList(false);
    handleOnClose();
    setIsError({ parent: false, pets: false });
    setParents(null);
    setPetExist(false);
    setShowCredit(false);
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setCloseDialog({
        isCreated: false,
        state: false
      });
    } else if (value === 'main-cancel') {
      handleCloseScreen();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    } else if (value === 'Discard') {
      setPetExist(false);
      setParents(null);
      // handleResetStates();
      handleOnClose();
      setShowCredit(false);
      setTrophyExist(true);
    } else if (value === 'Close') {
      handleOnClose();
      getEnrollmentDetailData();
      setFetchTrigger(true);
      // handleResetStates();
    }
  };

  const handleBookClassInfo = (type, value) => {
    if (type === 'parent') {
      setBookClass({ ...bookClass, [type]: value, pets: '' });
      setIsError({ ...isError, [type]: false, pets: false });
      setCreditClientId('')
    } else if (type === 'fromDate') {
      setBookClass({
        ...bookClass,
        [type]: formatTimeOrDate('date', value),
        toDate: null,
        toTime: ''
      });
      setIsError({ ...isError, [type]: false, toDate: false });
    } else if (type === 'toDate') {
      setBookClass({
        ...bookClass,
        [type]: formatTimeOrDate('date', value),
        toTime: ''
      });
      setIsError({ ...isError, [type]: false });
    } else if (type === 'fromTime') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, fromTimeFormat: false });
    } else if (type === 'toTime') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, toTimeFormat: false });
    } else if (type === 'occurence') {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false, intervalDays: false });
    } else {
      setBookClass({ ...bookClass, [type]: value });
      setIsError({ ...isError, [type]: false });
      if(type === 'pets'){
        setCreditClientId('')
      }
    }
    
  };
  const handleClientInfo = (e, value) => {
    setCreditClientId('')
    setParents(value);
    const data = value?.sortKey;
    setBookClass({ ...bookClass, parent: data, pets: '' });
    setIsError({ ...isError, parent: false, pets: false });
    setTrophyExist(true);
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = ['parent', 'pets'];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of alwaysCheck) {
      const value = bookClass[key];
      const isEmpty =
        value === '' ||
        value == null ||
        (Array?.isArray(value) && value?.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
    }
    setIsError(updatedErrors);
    return hasError;
  }
  console.log(totalCredits, 'updatedErrorsupdatedErrors');
  const handleConfirmClose = (value) => {
    if (value === 'Cancel') {
      // handleOnClose();
      setDialog({ ...dialog, bookClass: false, payLater: false });
      setWaitingList(false);
      setTotalCredits(true);
      // setNewSchedule(initials);
      setShowCredit(false);
      setMaxWaitList(false);
      setOpenDialog(false);
    }
    if (value === 'Save') {
      setOpenDialog(false);
      setWaitingList(true);
      setMaxWaitList(false);
    }
  };

  const getServicesBasedOnCategory = async () => {
    let categoryId = encodeURIComponent(newSchedule.serviceCategory);

    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllServices?locationId=${encodedLocationId}&categoryId=${categoryId}&status=1`
      );

      if (!response.data?.error) {
        setServiceData(response.data?.Items);
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };
  useEffect(() => {
    if (newSchedule.serviceCategory) {
      getServicesBasedOnCategory();
    }
  }, [newSchedule.serviceCategory]);

  const getPetTrophy = async () => {
    let encodedClientId = encodeURIComponent(bookClass.parent);
    let encodedPetId = encodeURIComponent(bookClass.pets);
    try {
      const response = await ApiService.get(
        `${BASE_URL}trophy/getTrophyByPet?clientId=${encodedClientId}&petId=${encodedPetId}`
      );

      if (!response.data?.error) {
        setTrophys(response.data?.Items);
        // setServiceData(response.data?.Items);
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };
  useEffect(() => {
    if (bookClass.pets) {
      getPetTrophy();
    }
  }, [bookClass.pets]);
  // useEffect(() => {
  //   if (trophys?.length>0&&prerequisites?.length>0) {
  //     // alert('rje')
  //     const uniqueTrophyIds = [...new Set(trophys.map(obj => obj.trophyId))];
  //     const allMatching = uniqueTrophyIds.every(trophyId => prerequisites.some(item => item.id === trophyId));
  //   }
  // }, [bookClass]);
  useEffect(() => {
    let foundService = null;
    serviceData.forEach((item) => {
      if (item.sortKey === newSchedule.serviceId) {
        foundService = item;
      }
    });
    setPrerequisites(foundService?.prerequisites);
  }, [serviceData]);

  useEffect(() => {
    let trophyIdsInDataset = trophys?.map((item) => item.trophyId);
    let extractedIds = Array.isArray(prerequisites)
      ? prerequisites.map((item) => item.id)
      : [];

    let allIdsExist =
      Array.isArray(extractedIds) &&
      extractedIds.every((idToCheck) => trophyIdsInDataset?.includes(idToCheck));

    if (extractedIds && extractedIds?.length > 0) {
      setTrophyExist(allIdsExist);
    } else {
      setTrophyExist(true);
    }
  }, [trophys]);

  const getPetsBasedOnClient = async () => {
    let clientId = encodeURIComponent(bookClass.parent);
    try {
      const response = await ApiService.get(
        `${BASE_URL}schedule/getAllPets?clientId=${clientId}`
      );
      if (!response.data?.error) {
        setAllPets(response.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with getting Pets list'
        );
      }
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  useEffect(() => {
    if (bookClass.parent) {
      getPetsBasedOnClient();
    }
  }, [bookClass.parent]);

  const handleAddClass = (e) => {
    e.preventDefault();
    if (!checkForEmptyValues()) {
      if (totalCredits>0&&bookClass.payLater) {
        setOpenPaylaterPopUp(true)
      } else {
        handleBookClass();
        setLoading(true);
      }
    }
  };
  const handleBookAppointment = (value) =>{
    if(value==='Cancel'){
      setOpenPaylaterPopUp(false)
    }else{
      setOpenPaylaterPopUp(false)
      handleBookClass();
    }
  }

  // useEffect(() => {
  //   handleBookClass()
  // }, [loadData]);
  //   useEffect(() => {
  //   if((allAttendies?.length===waitingNumber ||allAttendies?.length>waitingNumber) && dialog.bookClass ){
  //       setOpenDialog(true)
  //     }
  // }, [dialog.bookClass]);
  useEffect(() => {
    if (dialog.bookClass === true) {
      getEnrollmentDetail(); //  waitingNumber and handle the dialog opening in getEnrollmentDetail
    }
  }, [dialog.bookClass]);

  const getEnrollmentDetail = async () => {
    let data = {
      occurrenceId: newSchedule.id,
      locationId: locationId
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getAppointmentDetails`,
        data
      );
      let Item = response && response?.data;
      setAllAttendies(Item?.clientDetails);
      setAppointmentData(Item);
      const waiting = Item.maximumCapacity;
      const maxWaiting = Item.maximumCapacity + Item.maximumWaitngListCapacity;

      let actualAllAttendies = Item?.clientDetails?.filter(
        (item) =>
          item['status'] === 'Booked' ||
          item['status'] === 'booked' ||
          item['status'] === 'checkIn' ||
          item['status'] === 'arrivedLate'
      );

      if (actualAllAttendies?.length >= waiting) {
        setOpenDialog(true);
      } else if (actualAllAttendies?.length >= maxWaiting) {
        setMaxWaitList(true);
      } else {
        setOpenDialog(false);
        setMaxWaitList(false);
      }

      setWaitingNumber(waiting);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBookClass = async () => {
    setLoading(true)
    let childLastName = getValueFromId(
      bookClass.pets,
      allPets,
      'sortKey',
      'lastName'
    );
    let parentLastName = getValueFromId(
      bookClass.parent,
      allParents,
      'sortKey',
      'lastName'
    );
    const data = {
      updatedBy: userId,
      locationId: locationId,
      fromDate: formatTimeOrDate('date', newSchedule.toDate),
      type: 'occurrence',

      clientDetails: {
        payLater: bookClass.payLater,
        bookedBy: userName,
        serviceId: appointmentData.serviceId,
        serviceName: appointmentData.serviceName,
        categoryId: appointmentData.categoryId,
        categoryName: appointmentData.categoryName,
        creditAvailable: 0,
        checkIn: '00:00',
        // status: bookClass.appointmentStatus, //
        clientParentId: bookClass.parent,
        clientChildId: bookClass.pets,
        // clientChildBirthDate: bookClass.pets,
        // clientChildProfile: bookClass.pets,
        clientParentName: getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'firstName'
        ),
        // clientParentLastName:getValueFromId(bookClass.parent, allParents, 'sortKey', 'lastName') ||' ',
        clientChildName: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'firstName'
        ),
        // clientChildLastName: childLastName || ' ',
        clientChildProfile: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'profileImage'
        ),
        clientChildBirthDate: getValueFromId(
          bookClass.pets,
          allPets,
          'sortKey',
          'birthDate'
        ),
        clientEmailId: getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'email'
        ),
        clientParentPhoneNumber: getValueFromId(
          bookClass.parent,
          allParents,
          'sortKey',
          'mobilePhone'
        ),
        waitingList: waitingList
      }
    };
    if (parentLastName) {
      data.clientDetails.clientParentLastName = parentLastName;
    }
    if (childLastName) {
      data.clientDetails.clientChildLastName = childLastName;
    }
    if (totalCredits > 0 && !bookClass.payLater) {
      data.clientDetails.purchaseId = noOfCredit.map((item) => item.sortKey); // noOfCredit[0]?.sortKey;
    }
    if(creditClientId){
      data.clientDetails.creditClientId = creditClientId
    }

    const encodedOccurenceId = encodeURIComponent(newSchedule.id);
    try {
      const response = await ApiService.put(
        `${BASE_URL}schedule/bookAppointment?appointmentId=${encodedOccurenceId}`,
        data
      );
      // setLoading(true)

      if (!response?.error && response?.data?.statusCode === 400) {
        setLoading(false);
        setNewSchedule(initials);
        setShowErrorMessage(true);
        setErrorMessage(response.data.message);
        handleOnClose();
        setWaitingList(false);
        setShowCredit(false);
      } else {
        setBookClass({ ...bookClass, payLater: false, description: '' });
        setLoadData(true);
        setParents(null);
        setShowCredit(false);
        setLoading(false);
        setWaitingList(false);
        setCloseDialog({
          isCreated: true,
          state: true
        });
      }

      setBtnLoading(false);
    } catch (error) {
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      setLoading(false);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
    }finally{

      setLoadData(false);
    }

  };

  // const handleChangeStatus = async () => {
  //   const data = {
  //     updatedBy: userId,
  //     locationId: locationId,
  //     clientId: bookClass.parent,
  //     occurrenceId: newSchedule.id,
  //     checkIn: '00:00'
  //   };

  //   try {
  //     const response = await ApiService.put(
  //       `${BASE_URL}schedule/changeStatus`,
  //       data
  //     );
  //     if (!response.data?.error) {
  //       setLoadData(true);
  //       setCloseDialog({
  //         isCreated: true,
  //         state: true
  //       });
  //     } else {
  //       setShowErrorMessage(true);
  //       setErrorMessage(
  //         response.data?.message || 'Something went wrong with update a class'
  //       );
  //     }

  //     setBtnLoading(false);
  //   } catch (error) {
  //     setBtnLoading(false);
  //     setShowErrorMessage(true);
  //     setErrorMessage('Something went wrong, Try again!');
  //   }
  // };

  return (
    <>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <Loader IsLoading={isLoading} />
      <Dialog
        className="enrollClassDialog"
        open={dialog.bookClass}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('main-cancel')}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent className="custom-scroll">
          <Box
            sx={{ width: '100%' }}
            paddingBottom="20px"
            className="Main-container common-form-container"
          >
            <Box className="custom-form">
              <Grid mt={0} marginBottom="8px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'left',
                        alignItems: 'center',
                        gap: '8px'
                      }}
                    >
                      <h1 className="title primary-color">Enroll In A Class</h1>
                    </Box>
                  </Grid>

                  <Grid item>
                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                      }}
                      onClick={handleCloseScreen}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                columns={12}
                mt={bookClass.type === 'appointment' ? 0 : 1}
              >
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={parents}
                    onChange={handleClientInfo}
                    className="status-field field-label-css"
                    options={allParents ?? []}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.sortKey}>
                          {option.firstName} {option.lastName}
                        </li>
                      );
                    }}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: false,
                          type: 'search'
                        }}
                        // error={isError.parent}
                        helperText={isError.parent && 'Client is required'}
                        label={'SELECT CLIENT *'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    className="status-field field-label-css"
                    fullWidth
                    name="pets"
                    value={bookClass.pets}
                    onChange={handleBookClassInfo}
                    label="SELECT DOG *"
                    disabled={!bookClass.parent}
                    // error={isError.pets}
                    helperText={
                      (isError.pets && 'Dog is required') ||
                      (petExist &&
                        'This dog is already enrolled in the selected class.') ||
                      (!trophyExist &&
                        'This dog does not meet the prerequisites to enroll in this class.')
                    }
                    options={allPets?.map((item) => ({
                      label: `${item.firstName} ${item?.lastName}`,
                      value: item.sortKey
                    }))}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} columns={12} mt={1}>
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={bookClass.description}
                    onChange={handleBookClassInfo}
                    label="NOTES"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                columns={12}
                mt={0}
                className="fullWidth-btn"
              >
                <Grid item xs={12} sm={6} paddingTop={'10px !important'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bookClass.payLater}
                        onChange={(e) =>
                          handleBookClassInfo('payLater', e.target.checked)
                        }
                        disabled={petExist}
                        name="payLater"
                      />
                    }
                    label={
                      <span style={{ fontWeight: 'bold', color: 'black' }}>
                        Pay Later
                      </span>
                    }
                  />
                </Grid>
                {showCredit && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="16px"
                    paddingTop={'10px !important'}
                  >
                    <Typography variant="body2" color="#003087">
                      {totalCredits > 0 && sufficiantCredits ? (
                        <Typography
                          variant="body2"
                          fontWeight={800}
                          fontSize={16}
                          color="green"
                        >
                          Credit Available: {totalCredits}{' '}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          fontWeight={800}
                          fontSize={16}
                          color="red"
                        >
                          No credits available for this service{' '}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={`${
                  !petExist ? 'bookClassBtn' : 'bookClassBtndisabled'
                }`}
              >
                <CustomButton
                  variant="outline"
                  loading={btnLoading['add_schedule']}
                  onClick={handleAddClass}
                  disabled={
                    petExist || (totalCredits === 0 && !bookClass.payLater)
                  }
                  style={{
                    color: petExist ? 'inherit' : 'grey'
                  }}
                >
                  Book Now
                </CustomButton>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={closeDialog.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() =>
          handleDialog(closeDialog.creditApplied ? 'Continue' : 'Cancel')
        }
        aria-describedby="alert-dialog-slide-description"
      >
        {closeDialog.creditApplied ? null : (
          <DialogTitle className="alert-title align-center">
            {closeDialog.isCreated ? '' : 'Save Changes'}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!closeDialog.isCreated ? (
                <>
                  {closeDialog.creditApplied ? (
                    <Typography className="title-text-sub">
                      {closeDialog.insufficientCredits
                        ? 'Insufficient Credits'
                        : 'Credits Successfully Applied!'}
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      You have unsaved changes, Do you want to
                      <Typography> save them?</Typography>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography className="title-text-sub">
                  {`Class has been ${
                    isBookClassEdit ? 'updated' : 'booked'
                  } successfully!`}
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          {closeDialog.creditApplied ? (
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleDialog('Continue')}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(closeDialog.isCreated ? 'Close' : 'Discard')
                }
              >
                {closeDialog.isCreated ? 'Close' : 'Discard changes'}
              </Button>

              {!closeDialog.isCreated && (
                <Divider className="vertical-divider" />
              )}
              {!closeDialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go back to Editing
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-scheduling">
                The selected class is already filled to its
                <Typography className="title-text-scheduling">
                  maximum capacity. Do you want to add the
                </Typography>
                <Typography className="title-text-scheduling">
                  {' '}
                  dog to the waitlist?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleConfirmClose('Cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={maxWaitList}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-scheduling">
                The selected class and the maximum waitlist
                <Typography className="title-text-scheduling">
                  are already filled to their maximum capacity.
                </Typography>
                <Typography className="title-text-scheduling">
                  Do you want to override the maximum{' '}
                </Typography>
                <Typography className="title-text-scheduling">
                  {' '}
                  waitlist and add the dog to the waitlist?{' '}
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleConfirmClose('Cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Save')}
          >
            Override
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openVaccinationDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseConfirm()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                {vaccinationDetails.bordetellaAlert}

                <Typography className="title-text-sub">
                  {vaccinationDetails.dhppExpiration}
                </Typography>

                <Typography className="title-text-sub">
                  {vaccinationDetails.rabiesAlert}
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>

        <Divider />

        <DialogActions className="alert-button-control">
          <Button
            className="font-17 default-class no-hover"
            onClick={() => handleCloseConfirm()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPaylaterPopUp}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
            <Typography className="title-text-scheduling">
              The client already has available credits for this service.
                <Typography className="title-text-scheduling">
                Are you sure you want to choose the "Pay Later" option?
                </Typography>
                
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 cancel-btn-scheduling no-hover"
            onClick={() => handleBookAppointment('Cancel')}
          >
            No
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleBookAppointment('Save')}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
