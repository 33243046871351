import {
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const HolidayTable = ({
  setHolidayId,
  tableInfo,
  setHolidayCancelled,
  openDialog
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchor, setAnchor] = useState(null);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = useMemo(() => {
    if (!tableInfo) return [];
    return [...tableInfo].sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateB - dateA;
    });
  }, [tableInfo]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  const closeMenu = () => {
    setAnchor(null);
  };

  const openMenu = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setHolidayId(value);
    setAnchor(event.currentTarget);
  };

  const onMenuItemClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setHolidayCancelled(true);
    openDialog('Cancel Holiday');
    setAnchor(null);
  };

  return (
    <>
      <Typography className="header-blue-22" sx={{ my: 2 }}>
        All Holiday
      </Typography>
      <TableContainer className="client-table-container table-border-styling">
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell>Title</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData && paginatedData.length !== 0 ? (
              paginatedData.map((data) => (
                <TableRow className="background-blue" key={data.sortKey}>
                  <TableCell>{data.title}</TableCell>
                  <TableCell>
                    {formatDate(data.startDate.replaceAll('-', '/'))}
                  </TableCell>
                  <TableCell>
                    {formatDate(data.endDate.replaceAll('-', '/'))}
                  </TableCell>
                  <TableCell>
                    <MoreVertIcon
                      onClick={(e) => openMenu(e, data.sortKey)}
                      color="grey"
                      variant="contained"
                      className="three-dots-icon"
                    />
                    <Menu
                      open={Boolean(anchor)}
                      anchorEl={anchor}
                      onClose={closeMenu}
                      keepMounted
                      PaperProps={{
                        style: {
                          maxHeight: 40 * 5,
                          width: '25ch'
                        }
                      }}
                    >
                      <MenuItem
                        className="edit-btn"
                        onClick={onMenuItemClick}
                      >
                        Cancel Holiday
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell />
                <TableCell>No Holiday Has been added</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="-paginate-right" pb={2}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default HolidayTable;
