import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ApiService } from 'src/services/ApiService';

const renderDetailSection = (firstRow, secondRow) => (
  <Box
    className="section-main"
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: { xs: 'wrap', lg: 'nowrap' }
    }}
  >
    <Box>
      <Stack>{firstRow[0]} :</Stack>
      <Stack>{firstRow[1]}</Stack>
    </Box>
    <Box>
      <Stack>{secondRow[0]} :</Stack>
      <Stack>{secondRow[1]}</Stack>
    </Box>
  </Box>
);

const ContractDetailsCard = ({
  setIsLoading,
  setShowScreen,
  setShowErrorMessage,
  setErrorMessage,
  ContractAndMembershipDetailss,
  isLoading
}) => {
  const [tableData, setTableData] = useState([]);

  const fetchContractMembershipDetails = async () => {
    try {
      setIsLoading(true);
      const { locationId, orderId, itemId } = ContractAndMembershipDetailss;
      const encodedLocationId = encodeURIComponent(locationId);
      const encodedOrderId = encodeURIComponent(orderId);
      const encodedItemId = encodeURIComponent(itemId);

      const url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/getInlineItems?locationId=${encodedLocationId}&orderId=${encodedOrderId}&itemId=${encodedItemId}`;

      const response = await ApiService.get(url);
      setIsLoading(false);
      if (!response?.error && response.statusCode === 200) {
        setShowErrorMessage(false);
        setTableData(response?.data?.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message || 'Something went wrong!');
      }
    } catch (error) {
      console.warn('Api Error', error?.response);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchContractMembershipDetails();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
      >
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              onClick={() => setShowScreen(0)}
              className="back-arrow-close"
              sx={{ marginTop: '6px !important' }}
            >
              <ArrowLeft />
            </Box>
            <Typography className="header-blue-20">
              {isLoading ? 'Loading...' : 'Contract And Membership'}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        className="main-sales-detail"
        p={0}
        mt={2}
        key={ContractAndMembershipDetailss?.itemId}
      >
        <Box className="detailbox-main">
          <Box className="detail-section" sx={{ padding: '30px !important' }}>
            {renderDetailSection(
              [
                'NAME',
                ContractAndMembershipDetailss?.contractMembershipName || '-'
              ],
              ['TYPE', ContractAndMembershipDetailss?.Type]
            )}
            {renderDetailSection(
              [
                'BILLING CYCLE',
                ContractAndMembershipDetailss?.['Billing Cycle'] || '-'
              ],
              ['DURATION', ContractAndMembershipDetailss?.Duration || '-']
            )}
            {renderDetailSection(
              [
                'PURCHASE DATE',
                ContractAndMembershipDetailss?.['Purchase Date'] || '-'
              ],
              ['STATUS', ContractAndMembershipDetailss?.status || '-']
            )}

            {renderDetailSection(
              [
                'REFUND STATUS',
                ContractAndMembershipDetailss?.['Refund Status'] || '-'
              ],
              [
                'Total EMI Paid',
                ContractAndMembershipDetailss?.totalNumberOfEMIPaid || '-'
              ]
            )}
          </Box>
          <Box className="detail-table">
            <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '12px'
                  }}
                >
                  <CardHeader
                    title="All Items"
                    className="table-header-name"
                    sx={{ fontSize: '20px', color: '#003087' }}
                  />
                </Grid>
              </Grid>
              <TableContainer className="location-table-container">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell>Item Name</TableCell>
                      <TableCell>Item Type</TableCell>
                      <TableCell>Remaining Credits/Qty</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  {tableData.length > 0 ? (
                    tableData.map((item) => (
                      <TableBody>
                        <TableRow
                          hover
                          key={item?.purchaseItems?.itemId}
                          className="table-row cursor-pointer no-hover"
                        >
                          <TableCell
                            className="table-td -flex"
                            sx={{ minWidth: '200px' }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              sx={{ marginTop: 1 }}
                              gutterBottom
                            >
                              {item?.purchaseItems?.itemName || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="table-td -flex"
                            sx={{ minWidth: '200px' }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              sx={{ marginTop: 1 }}
                              gutterBottom
                            >
                              {item?.purchaseItems?.itemType || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-td">
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              sx={{ padding: '8px', marginTop: '2px' }}
                              gutterBottom
                            >
                              {item?.purchaseItems?.numberOfSessions || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell className="table-td">
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              color="text.primary"
                              gutterBottom
                              noWrap
                            >
                              $
                              {parseFloat(item?.purchaseItems?.price)?.toFixed(
                                2
                              ) || '0.00'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableRow
                        hover
                        className="table-row cursor-pointer no-hover"
                      >
                        <TableCell
                          className="table-td -flex"
                          sx={{ minWidth: '200px' }}
                          colSpan={4}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            sx={{ marginTop: 1 }}
                            gutterBottom
                          >
                            {isLoading ? 'Loading...' : 'No Data Found!'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContractDetailsCard;
