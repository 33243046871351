import axios from 'axios';

let instance = axios.create();

// Add a request interceptor to include the token in the headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to handle token expiration
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = ''; // Fetch or get refresh token from localStorage or wherever you store it
        const response = await axios.post('refresh_token_url', {
          refreshToken
        });
        const newToken = response.data.accessToken;
        localStorage.setItem('accessToken', newToken);
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axios(originalRequest);
      } catch (error) {
        // If refresh token fails, redirect to login or handle it accordingly
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const ApiService = {
  // Function to make a GET request
  get: async (url) => {
    try {
      const response = await instance.get(url);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Function to make a POST request
  post: async (url, data) => {
    try {
      const response = await instance.post(url, data);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Function to make a PUT request
  put: async (url, data) => {
    try {
      const response = await instance.put(url, data);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Function to make a DELETE request
  delete: async (url, data) => {
    try {
      const response = await instance.delete(url, { data: data });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  }
};

function handleApiError(error) {
  let statusCode = error?.response?.status || 'Unknown';
  let message =
    error?.response?.data?.message || error.message || 'Something went wrong';
  return { statusCode, message };
}
