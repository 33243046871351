import heic2any from 'heic2any';

/* Function to set manufacturer product name  */
export const manufacturerProductNameValidate = (
  manufacturerProductName,
  setIsFieldUpdated,
  setmanufacturerProductName,
  setmanufacturerProductNameError
) => {
  if (!manufacturerProductName) {
    setIsFieldUpdated(true);
    setmanufacturerProductName('');
  } else {
    setIsFieldUpdated(true);
    setmanufacturerProductNameError('');

    setmanufacturerProductName(manufacturerProductName);
  }
};

/* Function for validation of Manufacturer  */
export const manufacturervalidate = (
  manufacturer,
  setIsFieldUpdated,
  setmanufacturer,
  setmanufacturerError
) => {
  let manufacturerPattern = /[a-zA-Z ]/;
  if (!manufacturer) {
    setIsFieldUpdated(true);
    setmanufacturer('');
  } else if (!manufacturerPattern.test(manufacturer)) {
    setIsFieldUpdated(true);
    setmanufacturerError('Manufacturer should be text');
    setmanufacturer(manufacturer);
  } else {
    setIsFieldUpdated(true);
    setmanufacturerError('');
    setmanufacturer(manufacturer);
  }
};

/* Function for validation of Zoom room product name  */
export const zoomRoomProductNamevalidate = (
  zoomRoomProductName,
  setzoomRoomProductName,
  setIsFieldUpdated,
  setzoomRoomProductNameError
) => {
  if (!zoomRoomProductName) {
    setzoomRoomProductName(
      zoomRoomProductName !== '' ? '' : zoomRoomProductName
    );
    setIsFieldUpdated(true);
  } else {
    setIsFieldUpdated(true);
    setzoomRoomProductNameError('');
    setzoomRoomProductName(zoomRoomProductName);
  }
};

/* Function for setting value of product category validate */
export const productCategoryvalidate = (
  productCategory,
  setIsFieldUpdated,
  setproductCategoryError,
  setproductCategoryId
) => {
  if (!productCategory) {
    setIsFieldUpdated(true);
    setproductCategoryError(false);
    setproductCategoryId('');
  } else {
    setIsFieldUpdated(true);
    setproductCategoryError(false);
    setproductCategoryId(productCategory);
  }
};

/* Function for state drop-down of varient type */
export const varientTypeValidate = (
  targetValue,
  number,
  getVariants,
  setvariantId,
  setTypesVar1,
  setTypesVar2,
  variant,
  setVariant,
  setIsFieldUpdated
) => {
  const variantObj = [...variant];
  if (!targetValue) {
    setIsFieldUpdated(true);
  } else {
    variantObj[number] = {
      valueId: '',
      value: ''
    };
    const typesVarArray = [setTypesVar1, setTypesVar2]; // Add more if needed

    if (typesVarArray[number]) {
      typesVarArray[number]({
        variantId: targetValue?.variantId,
        variantType: targetValue?.variantType
      });
    }
    setVariant(variantObj);
    setIsFieldUpdated(true);
    getVariants(targetValue?.variantId, number, 'setDefault');
    setvariantId(targetValue?.variantId);
  }
};

/* Function for setting varient */
export const varientSet = (
  varientValue,
  number,
  variant,
  setVariant,
  setIsFieldUpdated,
  setvalueId
) => {
  let variantObj = [...variant];

  if (!varientValue?.sortKey) {
    setIsFieldUpdated(true);
  } else {
    variantObj[number] = {
      valueId: varientValue?.sortKey,
      value: varientValue?.value
    };
    setVariant(variantObj);
    setIsFieldUpdated(false);
    setvalueId(varientValue);
  }
};

/* Function to set value of price  */
export const priceSet = (event, setprice) => {
  let input = event.target.value;
  input = input.replace(/[^0-9.]/g, '');
  // Allow only two digits after the decimal point
  const decimalIndex = input.indexOf('.');
  if (decimalIndex !== -1) {
    const parts = input.split('.');
    input = parts[0] + '.' + parts[1].slice(0, 2);
  }
  setprice(input);
};

/* Function to set value of selling point */
export const sellingpointSet = (
  sellingPoint,
  setIsFieldUpdated,
  setsellingPoint
) => {
  setIsFieldUpdated(true);
  setsellingPoint(sellingPoint);
};

/* Function to set value of product description */
export const productDescriptionSet = (
  productDescription,
  setIsFieldUpdated,
  setproductDescriptionError,
  setproductDescription
) => {
  setIsFieldUpdated(true);
  setproductDescriptionError('');
  setproductDescription(productDescription);
};

/* Function for validation of SKU Code  */
export const skuCodeValidate = (
  skuCode,
  setIsFieldUpdated,
  setSkuCodeError,
  setSkuCode
) => {
  setIsFieldUpdated(true);
  setSkuCodeError('');
  setSkuCode(skuCode);
};

/* Function to get uploaded image */
export const handleFileInput = async (
  event,
  setMultipleImage,
  setImageError,
  setIsFieldUpdated,
  multipleImage
) => {
  if (event.target.files.length !== 0) {
    const file = event.target.files[0];
    const fileName = file.name.toLowerCase();

    if (
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg') ||
      fileName.endsWith('.jpg') ||
      fileName.endsWith('.heic')
    ) {
      if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.8
          });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          const uploadedImage = {
            previewURL: URL.createObjectURL(jpegFile),
            id: Math.random().toString(36).substring(7),
            file: jpegFile
          };
          setMultipleImage([...multipleImage, uploadedImage]);
          setImageError('');
          setIsFieldUpdated(true);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setImageError('Error handling HEIC file.');
        }
      } else if (file.type === 'image/gif') {
        setImageError(
          'Please upload a supported image format (excluding GIF and HEIC).'
        );
      } else {
        const uploadedImages = Array.from(event.target.files).map((file) => ({
          previewURL: URL.createObjectURL(file),
          id: Math.random().toString(36).substring(7),
          file: file
        }));

        setMultipleImage([...multipleImage, ...uploadedImages]);
        setImageError('');
        setIsFieldUpdated(true);
      }
    } else {
      setImageError(
        'Please upload only png, jpeg, jpg, or heic format images.'
      );
    }
    event.target.value = '';
  }
};
