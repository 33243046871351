import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  DialogActions,
  Button,
  Slide
} from '@mui/material';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ApiService } from 'src/services/ApiService';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const TrophyEarnedTable = (props) => {
  const {
    tableRows,
    searchQuery,
    setLoading,

    setShowErrorMessage,
    setErrorMessage,
    setShowSuccessMessage,
    setSuccessMessage,
    petStatus
  } = props;

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [getPetItem, setGetPetItem] = React.useState([]);
  const location = useLocation();

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment(date).format('MMM Do, YYYY') : '';
    return utc;
  };

  // Method to unassign the trophy
  const unassignTrophy = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}trophy/removeTrophy`;

    let payload = {
      clientId: getPetItem?.clientId,
      id: getPetItem?.id,
      updatedBy: getPetItem?.createdBy
    };
    try {
      const data = await ApiService.post(`${url}`, payload);

      if (data?.statusCode === 200 && !data?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(
          data?.message || 'Trophy has been removed successfully!'
        );
      }
    } catch (error) {
      console.error('Error:', error);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Something went wrong!');
      setLoading(false);
    }
  };

  const openMenu = (pet, event) => {
    event.preventDefault();
    event.stopPropagation();
    setGetPetItem(pet);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(null);
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Unassign') {
      unassignTrophy();
    }
    setOpen(false);
    setAnchor(null);
    setGetPetItem([]);
  };

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Trophy Name</TableCell>
                <TableCell className="text-transform-none">
                  Date of Earned
                </TableCell>
                <TableCell>Trophy Assigned By</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {(paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      key={row.sortKey}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      // selected={isItemSelected}
                      className="table-row cursor-pointer"
                      // onClick={(e) => openDetail(row.sortKey, 3, e)}
                    >
                      <TableCell className="table-td" sx={{ display: 'flex' }}>
                        <Avatar
                          alt="icon"
                          src={
                            row.trophyImage ||
                            '/static/images/logo/clienticon.png'
                          }
                          sx={{ marginRight: 2, float: 'left' }}
                          className="card-avatar-img align-self-center"
                        />
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ marginTop: 1 }}
                          gutterBottom
                          noWrap
                        >
                          {row?.trophyName}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.createdAt && dateFormate(row?.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.createdByName}
                        </Typography>
                      </TableCell>
                      {location.pathname === '/client-management' && (
                        <TableCell className="table-td">
                          <MoreVertIcon
                            onClick={(e) => openMenu(row, e)}
                            color="grey"
                            variant="contained"
                            className="three-dots-icon"
                          />

                          <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            keepMounted
                            PaperProps={{
                              style: {
                                maxHeight: 40 * 5,
                                width: '20ch'
                              }
                            }}
                          >
                            <MenuItem
                              onClick={(e) => onMenuItemClick(e)}
                              disabled={petStatus === 0}
                            >
                              Remove Trophy
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })) || (
                <TableRow>
                  <TableCell colSpan={6} className="table-td">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ marginTop: 1 }}
                      gutterBottom
                      noWrap
                    >
                      No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>

      {/* Remove trophy confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Remove Trophy'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Caution, trophy deletion may impact the client's ability to
                schedule future services for this dog. Please check for any
                currently scheduled future services that would be impacted by
                this deletion and update those services manually.
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Unassign')}
          >
            Yes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
