import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import NestedTable from './NestedTable';

const ReturnItems = ({ tableData, clientDetail }) => {
  const tableCells = [
    'Item Name',
    'Credits/Quantity',
    'Remaining Credits',
    'Variants',
    'Price',
    'Membership Discount',
    'Bundle Discount',
    'Promo Code Discount',
    'Tax'
  ];

  if (clientDetail?.paymentSourceType === 'COMP') {
    tableCells.push('Comp Discount');
  }
  tableCells.push('Net Price');

  return (
    <TableContainer className="location-table-container return-table">
      <Table>
        <TableHead>
          <TableRow className="table-header">
            {tableCells?.map((item) => {
              return <TableCell>{item}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData?.map((tableItem) => {
              const containInline =
                tableItem?.itemType === 'PricingOption' ||
                tableItem?.itemType === 'Bundle' ||
                tableItem?.itemType === 'Membership' ||
                tableItem?.itemType === 'Contract';
              const bundlePriceDifference = `$${parseFloat(
                tableItem.purchaseItems.actualPrice - tableItem.price
              )?.toFixed(2)}`;
              const compDiscount = parseFloat(tableItem?.price) + parseFloat(tableItem?.taxAmount) 
              return (
                <>
                  <TableRow
                    hover
                    key={tableItem?.itemId}
                    // selected={isItemSelected}
                    className={`table-row cursor-pointer ${
                      containInline ? 'first-row' : ''
                    }`}
                  >
                    <TableCell
                      className="table-td -flex"
                      sx={{ minWidth: '200px' }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                      >
                        {tableItem?.itemName || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ padding: '8px', marginTop: '2px' }}
                        gutterBottom
                      >
                        {tableItem?.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ padding: '8px', marginTop: '2px' }}
                        gutterBottom
                      >
                        {tableItem?.remainingSession ?? '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {tableItem?.itemType === 'Product'
                          ? tableItem?.purchaseItems?.variants
                              ?.filter((value) => value?.value)
                              ?.map?.((item, index, array) =>
                                index === array.length - 1
                                  ? item?.value
                                  : `${item?.value}, `
                              )
                              .join('') || '-'
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {tableItem?.itemType === 'Bundle' &&
                        (tableItem?.purchaseItems?.actualPrice ||
                          tableItem?.purchaseItems?.actualPrice === 0)
                          ? `$${parseFloat(
                              tableItem?.purchaseItems?.actualPrice
                            )?.toFixed(2)}`
                          : tableItem?.price
                          ? `$${parseFloat(tableItem?.price)?.toFixed(2)}`
                          : '$0.00'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* {tableItem?.price ? `$${parseFloat(tableItem?.price)?.toFixed(2)}` : '$0.00'} */}
                        {tableItem?.discountDetails?.memberShip?.discountApplied
                          ? `${parseFloat(
                              tableItem?.discountDetails?.memberShip
                                ?.discountApplied
                            )?.toFixed(2)}%`
                          : '0.00%'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {tableItem?.itemType === 'Bundle' &&
                        (tableItem?.purchaseItems?.actualPrice ||
                          tableItem?.purchaseItems?.actualPrice === 0 ||
                          tableItem?.price ||
                          tableItem?.price === 0)
                          ? bundlePriceDifference
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* {tableItem?.price ? `$${parseFloat(tableItem?.price)?.toFixed(2)}` : '$0.00'} */}
                        {tableItem?.discountDetails?.promoCodes?.discountApplied
                          ? `${
                              tableItem?.discountDetails?.promoCodes
                                ?.discountType === 'Fixed'
                                ? '$'
                                : ''
                            }${parseFloat(
                              tableItem?.discountDetails?.promoCodes
                                ?.discountApplied
                            )?.toFixed(2)} ${
                              tableItem?.discountDetails?.promoCodes
                                ?.discountType === 'Percentage'
                                ? '%'
                                : ''
                            }`
                          : '$0.00'}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {/* {tableItem?.price ? `$${parseFloat(tableItem?.price)?.toFixed(2)}` : '$0.00'} */}
                          {parseFloat(tableItem?.taxRate || 0)?.toFixed(2)}%
                        </Typography>
                      </TableCell>
                    {clientDetail?.paymentSourceType === 'COMP' && (
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {/* {tableItem?.price ? `$${parseFloat(tableItem?.price)?.toFixed(2)}` : '$0.00'} */}
                          ${parseFloat(compDiscount || 0)?.toFixed(2)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        $
                        {parseFloat(
                          clientDetail?.paymentSourceType === 'COMP'
                            ? 0
                            : tableItem?.discountDetails?.promoCodes
                                ?.discountPrice ||
                              tableItem?.discountDetails?.promoCodes
                                ?.discountPrice === 0
                            ? tableItem.discountDetails.promoCodes
                                .discountPrice * tableItem.quantity
                            : tableItem?.discountDetails?.memberShip
                                ?.discountPrice
                            ? tableItem.discountDetails.memberShip
                                .discountPrice * tableItem.quantity
                            : tableItem?.price && tableItem?.quantity
                            ? tableItem.price * tableItem.quantity
                            : 0
                        )?.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {containInline &&
                      tableItem?.itemType !== 'PricingOption' &&
                      tableItem?.itemType !== 'Product' && (
                        <TableCell className="table-td" colSpan={10}>
                          <NestedTable tableItem={tableItem} />
                        </TableCell>
                      )}
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReturnItems;
