import React from 'react';
import { Box, Typography } from '@mui/material';
import { DynamicButton } from '../../../components/Checkout';
import { useNavigate } from 'react-router-dom';

export const ReturnFailed = ({ selectedClient }) => {
  const navigate = useNavigate();
  const paramsClientId =
    selectedClient?.clientId &&
    selectedClient?.clientId?.replace('#CLIENT#', '');

  return (
    <>
      <Box className="orderSuccess">
        <Typography
          variant="h1"
          align="center"
          color="primary"
          style={{ marginBottom: '15px' }}
        >
          Return Failed!
        </Typography>

        <DynamicButton
          title={'Back to POS Dashboard'}
          onClick={() => {
            navigate(
              paramsClientId
                ? `/sales-history/${paramsClientId}`
                : `/sales-history`
            );
            window.location.reload();
          }}
          variant="text"
          style={{
            padding: '0',
            maxWidth: 'max-content',
            background: 'transparent',
            textDecoration: 'underline',
            fontWeight: '600',
            fontSize: '15px',
            margin: '0 auto',
            display: 'block'
          }}
        />
      </Box>
    </>
  );
};
