import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { useSalesDetailFunctions } from './SalesDetailFunctions';
import DetailBox from './DetailBox';
import ReturnForm from './ReturnForm';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import Loader from 'src/components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ReturnSuccess } from './ReturnSuccess';
import { ReturnFailed } from '../ReturnFailed';

const SalesDetail = ({ detail }) => {
  const navigate = useNavigate();
  const { orderId: selectedOrderId } = useParams();

  const [clientDetail, setClientDetail] = useState(detail);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(0); // Initialize setActive state
  const [receiptData, setReceiptData] = useState();
  const [refundOrderId, setRefundOrderId] = useState('');

  const { getClientDetail, returnFormValidation } = useSalesDetailFunctions(
    detail,
    setActive, // Pass setActive to the function
    selectedOrderId
  );

  const [tableData, setTableData] = useState([]);
  const [returnStatus, setReturnStatus] = useState(false);
  const [errors, setErrors] = useState({
    refundMethod: null,
    refundReason: null
  });

  const [formData, setFormData] = useState({
    refundMethod: null,
    refundReason: null,
    refundAmount: null
  });

  const modifiedCLientId = clientDetail?.clientId?.replace('#CLIENT#', '');

  useEffect(() => {
    let result;
    if (
      clientDetail?.paymentSourceType === 'CASH - GIFTCARD' ||
      clientDetail?.paymentSourceType === 'CARD - GIFTCARD'
    ) {
      const parts = clientDetail?.paymentSourceType
        ?.split('-')
        ?.map((part) => part?.trim());
      result = parts[0];
    } else {
      result = clientDetail?.paymentSourceType;
    }

    setFormData({
      ...formData,
      originalPaymentSource: result,
      refundAmount: clientDetail?.totalSquareAmountWithTaxInDollar
    });
  }, [clientDetail]);

  useEffect(() => {
    getClientDetail(setClientDetail, setTableData);
  }, [detail]);

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      {(() => {
        switch (active) {
          case 1:
            return (
              <ReturnSuccess
                setActive={setActive}
                receiptData={receiptData}
                setReceiptData={setReceiptData}
                refundOrderId={refundOrderId}
                selectedClient={clientDetail}
              />
            );
          case 2:
            return (
              <ReturnFailed
                setActive={setActive}
                selectedClient={clientDetail}
              />
            );
          default:
            return (
              <Box className="main-sales-detail">
                <Typography className="page-title" variant="h1">
                  <Box
                    className="back-arrow-close"
                    onClick={() =>
                      navigate(`/sales-history/${modifiedCLientId}`)
                    }
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '15px'
                    }}
                  >
                    <svg
                      width="27"
                      height="19"
                      viewBox="0 0 27 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                        fill="#003087"
                      />
                    </svg>
                    Order:
                    {clientDetail?.transactionId || clientDetail?.orderId}
                  </Box>
                </Typography>

                <DetailBox
                  clientDetail={clientDetail}
                  tableData={tableData}
                  detail={detail}
                  setActive={setActive}
                  selectedOrderId={selectedOrderId}
                />
                {returnStatus && (
                  <ReturnForm
                    formData={formData}
                    setFormData={setFormData}
                    detail={detail}
                    setActive={setActive}
                    selectedOrderId={selectedOrderId}
                    errors={errors}
                    setErrors={setErrors}
                    clientDetail={clientDetail}
                  />
                )}
                <Box className="detail-buttons-main" mb={2}>
                  <Stack
                    spacing={1}
                    direction="row"
                    className="gift-card-buttons"
                  >
                    <Button
                      variant="outlined"
                      className="top-button-style"
                      onClick={() => {
                        setReturnStatus(false);
                        navigate(`/sales-history/${modifiedCLientId}`);
                      }}
                    >
                      Cancel
                    </Button>
                    {!returnStatus ? (
                      <>
                        {clientDetail?.refundStatus !== 'COMPLETED' &&
                          clientDetail?.refundStatus !== 'PENDING' &&
                          clientDetail?.sqaureOrderState !== 'OPEN' &&
                          (clientDetail?.paymentSourceType === 'COMP'
                            ? true
                            : clientDetail?.totalSquareAmountWithTaxInDollar !==
                              0) && (
                            <Button
                              variant="contained"
                              className="top-button-style"
                              onClick={() => setReturnStatus(true)}
                              onAbort={clientDetail?.refundStatus}
                            >
                              Return
                            </Button>
                          )}
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        className="top-button-style"
                        onClick={() =>
                          returnFormValidation(
                            clientDetail,
                            formData,
                            setErrors,
                            setShowMessage,
                            setErrorMessage,
                            setIsLoading,

                            setRefundOrderId
                          )
                        }
                      >
                        Proceed Now
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Box>
            );
        }
      })()}
    </>
  );
};

export default SalesDetail;
