import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import React from 'react';
import './App.css';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './contexts/UserContext';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* <ToastContainer /> */}
      <CssBaseline />
      <UserProvider>{content}</UserProvider>
      {/* </LocalizationProvider> */}
    </ThemeProvider>
  );
}

export default App;
