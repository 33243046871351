import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  DialogContentText,
  DialogTitle,
  Grid,
  FormHelperText,
  TextField,
  Slide,
  Typography
} from '@mui/material';
// FormControl,  InputLabel, MenuItem, Select,
import { Toggle } from 'src/components/Global';
import { useNavigate } from 'react-router-dom';
// import { getLocalData, setFullLocationId, enterNumberOnly } from '../../../../utils';
import { enterNumberOnly } from '../../../../utils';
import { ApiService } from '../../../../services/ApiService';
import Loader from '../../../../components/Loader';
import InputAdornment from '@mui/material/InputAdornment';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddBundles = ({
  setOpenAddBundle,
  openAddBundleModal,
  setAddBundleModal,
  getBundles,
  setShowScreen,
  productData,
  pricingData
}) => {
  // const locationId = setFullLocationId((getLocalData('locationId')));

  let userId = getLocalData('userId');
  const navigate = useNavigate();
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });
    // check if the user is logged in or not
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [AddBundles, navigate]);
  const [pricingValue, setpricingValue] = useState([]);
  const [productValue, setProductValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [pricingData, setPricingData] = useState('');
  // const [services, setServices] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  // const [productData, setProductData] = useState('');
  const [productError, setProductError] = useState('');

  const [postProducts, setPostProducts] = useState([]);
  const [sellOnline, setSellOnline] = useState(true);
  //   const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setendDate] = useState('');
  const [endDateError, setEndDateError] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalDiscount, settotalDiscount] = useState('');
  const [bundleSku, setBundleSku] = useState('');
  const [bundleSkuError, setBundleSkuError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };
  const validateStartDate = (startDate) => {
    setStartDateError('');
    if (startDate === '01-01-1970') {
      return;
    }
    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);
    setStartDate(startDate);
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date must be greater than start date');
    } else {
      setStartDateError('');
      setEndDateError('');
    }
  };

  const validateEndDate = (endDate) => {
    if (endDate === '01-01-1970') {
      return;
    }

    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);
    setendDate(endDate);
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date must be greater than start date');
    } else {
      setEndDateError('');
    }
  };
  // const [products, setProducts] = useState('');
  const [name, setName] = useState('');
  // const [bundlePrice, setBundlePrice] = useState('');

  /* States for error messages */
  const [nameError, setNameError] = useState('');
  // const [bundlePriceError, setBundlePriceError] = useState('');
  const [discount, setDiscount] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [discountError, setDiscountError] = useState('');

  useEffect(() => {
    // setIsCreated(false);
    //  getPricingOptions();
    // getProducts();
    getmaxSku();
  }, []);

  const handleProductSelection = (selectedOptions) => {
    const id = selectedOptions.map((option) => {
      return option.sortKey;
    });
    // console.log(selectedOptions, 'selectedOptions', id);

    const selectedProduct = id.map((item) => {
      const [Id] = item.split('|');
      return Id;
    });

    // setProduct(selectedProduct);
    setProductValue(selectedOptions);
    setProductError('');
    settotalDiscount('');
    let postProduct = productData.filter((products) => {
      return selectedProduct.includes(products.sortKey);
    });

    setPostProducts(postProduct);
  };

  const handleServiceSelection = (selectedOptions) => {
    const id = selectedOptions.map((option) => {
      return option.sortKey;
    });
    // console.log(selectedOptions, 'selectedOptions', id);

    const selectedServices = id.map((item) => {
      const [Id] = item.split('|');
      return Id;
    });

    // setServices(selectedServices);
    setpricingValue(selectedOptions);
    setProductError('');
    settotalDiscount('');
    let postService = pricingData.filter((services) => {
      return selectedServices.includes(services.sortKey);
    });

    setPricingOptions(postService);
  };

  const handleInputChange = (e) => {
    setDiscountError('');
    const { name, value } = e.target;

    if (name === 'Product') {
      const selectedProduct = value.map((item) => {
        const [Id] = item.split('|');
        return Id;
      });

      // setProduct(selectedProduct);
      setProductValue(value);
      let postProduct = productData.filter((products) => {
        return selectedProduct.includes(products.sortKey);
      });

      setPostProducts(postProduct);
    }
    if (name === 'PricingOptions') {
      const selectedServices = value.map((item) => {
        const [Id] = item.split('|');
        return Id;
      });

      // setServices(selectedServices);
      setpricingValue(value);
      let postService = pricingData.filter((services) => {
        return selectedServices.includes(services.sortKey);
      });

      setPricingOptions(postService);
    }

    if (name === 'bundleName') {
      if (!value) {
        setNameError('Bundle name is required');
        setName(value);
      } else {
        setNameError('');
        setName(value);
        setIsFieldUpdated(true);
      }
    }
    // setDescription

    if (name === 'description') {
      if (!value) {
        setDescriptionError('Description is required');
        setDescription(value);
        setIsFieldUpdated(false);
      } else {
        setDescriptionError('');
        setDescription(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'discount') {
      if (!value) {
        setDiscountError('Discount is required');
        setDiscount(value);
      } else {
        let discountNo = enterNumberOnly(value);

        if (discountNo > 100) {
          setDiscountError('Discount cannot be greater than 100');
          // setDiscount(100); // Cap the discount at 100 if it's greater
        } else {
          setDiscountError('');
          setDiscount(discountNo);
        }

        setIsFieldUpdated(true);
      }
    }
    if (name === 'bundleSku') {
      if (!value) {
        setBundleSkuError('Bundle sku is required');
        setBundleSku(value);
      } else {
        setBundleSkuError('');
        setBundleSku(value);
        setIsFieldUpdated(true);
      }
    }
  };
  const toggleButton = (e) => {
    if (e === 'sellOnline') {
      setSellOnline(!sellOnline);
    }
  };

  const AddBundlesData = (e) => {
    setDisabledButton(true);

    e.preventDefault();
    e.stopPropagation();
    settotalDiscount('');
    setDiscountError('');
    if (!discount) {
      setDisabledButton(false);
      setDiscountError('Please add discount');
    }
    if (!name) {
      setDisabledButton(false);
      setNameError('Name is required');
    }
    if (!description) {
      setDisabledButton(false);
      setDescriptionError('Please add description');
    }
    if (!bundleSku) {
      setDisabledButton(false);
      setBundleSkuError('Bundle SKU is required');
    }
    if (!startDate && endDate) {
      setDisabledButton(false);
      setStartDateError('Start Date is required when End Date is select');
      return;
    }

    const formattedStartDate = formatDateForComparison(startDate);
    const formattedEndDate = formatDateForComparison(endDate);
    if (moment(formattedStartDate).isAfter(moment(formattedEndDate))) {
      setEndDateError('End date must be greater than start date');
      setDisabledButton(false);
      return;
    }

    if (pricingValue.length === 0 && productValue.length === 0) {
      setDisabledButton(false);
      setProductError('Please select at least one product or pricing option');
    }
    if (
      discount &&
      name &&
      description &&
      bundleSku &&
      (pricingValue.length > 0 || productValue.length > 0)
    ) {
      if (totalDiscountPricedata === 0) {
        setDisabledButton(false);
        settotalDiscount(
          'Change the pricing option and product prices; they cannot be zero.'
        );
      } else {
        createBundle();
      }
    }
  };

  // let totalPriceservice = postServices.reduce((pre, data) => pre + parseInt(data.defaultPrice), 0);
  const pattern = /^\d{0,4}(\.\d{0,2})?/;
  let totalPriceservice = pricingOptions.reduce(
    (pre, data) => pre + parseFloat(data?.price || 0),
    0
  );

  let totalPriceservicedata;
  if (!pattern.test(totalPriceservice)) {
    totalPriceservicedata = totalPriceservice;
  } else {
    totalPriceservicedata = parseFloat(totalPriceservice)
      .toFixed(2)
      .replace(/\.?0*$/, '');
  }

  let totalPriceproduct = postProducts.reduce(
    (pre, data) => pre + parseFloat(data?.price || 0),
    0
  );

  let totalPriceproductdata;
  if (!pattern.test(totalPriceproduct)) {
    totalPriceproductdata = totalPriceproduct;
  } else {
    totalPriceproductdata = parseFloat(totalPriceproduct)
      .toFixed(2)
      .replace(/\.?0*$/, '');
  }

  let totalPricedata;
  let totalPrice =
    parseFloat(totalPriceservicedata) + parseFloat(totalPriceproductdata);
  if (Number.isNaN(totalPrice)) totalPrice = 0;
  if (!pattern.test(totalPrice)) {
    totalPricedata = totalPrice;
  } else {
    totalPricedata = parseFloat(totalPrice)
      .toFixed(2)
      .replace(/\.?0*$/, '');
  }
  let totalDiscountPrice;
  let totalDiscountPricedata;

  totalDiscountPrice = totalPrice - (totalPrice * discount) / 100;

  if (!pattern.test(totalDiscountPrice)) {
    totalDiscountPricedata = totalDiscountPrice; // Set to a default value or handle the error as needed
  } else {
    // Format to 2 decimal places without trailing zeros
    totalDiscountPricedata = parseFloat(totalDiscountPrice)
      .toFixed(2)
      .replace(/\.?0*$/, '');
  }

  const createBundle = async () => {
    setIsLoading(true);
    // let encodedLocationId = encodeURIComponent(locationId);
    let items = [];

    if (pricingOptions?.length > 0) {
      let itemPricingOptionsObj = pricingOptions.map((PricingOption) => {
        // Prepare the base object
        let baseObj = {
          itemType: 'PricingOption',
          itemId: PricingOption?.sortKey,
          itemName: PricingOption?.name,
          itemCategoryId: PricingOption?.categoryId,
          itemCategoryName: PricingOption?.categoryName,
          price: PricingOption?.price ?? 0,
          sellOnline: PricingOption?.sellOnline ?? false,
          free: PricingOption?.free ?? false,
          sharable: PricingOption?.sharable ?? false,
          restricted: PricingOption?.restricted ?? false,
          numberOfSessions: PricingOption?.numberOfSessions ?? 0,
          creditsValidityStarts: PricingOption?.creditsValidityStarts,
          services: PricingOption?.services,
          description: PricingOption?.description,
          schedulingLimitation: PricingOption?.schedulingLimitation,
          setTimeRange: PricingOption?.setTimeRange,
          creditsValidityExpires: PricingOption?.creditsValidityExpires
        };

        // Conditionally add startTime and endTime if setTimeRange is true
        if (PricingOption?.setTimeRange) {
          baseObj = {
            ...baseObj,
            startTime: PricingOption.startTime, // Replace with your actual startTime source
            endTime: PricingOption.endTime // Replace with your actual endTime source
          };
        }

        return baseObj;
      });

      items.push(...itemPricingOptionsObj);
    }

    if (postProducts.length > 0) {
      let itemProductObj = postProducts?.map((ProductData) => {
        return {
          itemType: 'Product',
          itemId: ProductData?.sortKey,
          itemCategoryId: ProductData?.categoryId,
          itemCategoryName: ProductData?.categoryName,
          itemName: ProductData?.fName,
          // itemVariantId: ProductData?.variantId,
          // itemVariantType: ProductData?.variant?.variantType,
          // itemValueId: ProductData?.valueId,
          price: ProductData?.price ?? 0,
          // itemVariantValue: ProductData?.variant?.value
          variants: ProductData?.variants ?? [],
          taxExempt: ProductData?.taxExempt || false
        };
      });
      items.push(...itemProductObj);
    }

    const data = {
      bundleName: name,
      // locationId: locationId,
      sku: bundleSku,
      startDate: startDate,
      endDate: endDate,
      sellOnline: sellOnline,
      bundlePrice: totalDiscountPricedata,
      discount: discount,
      description: description,
      actualPrice: totalPricedata,
      items: items,
      createdBy: userId
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}bundles/createBundle`,
        data
      );
      if (response.statusCode && !response.error) {
        setIsLoading(false);
        setIsCreated(true);
        setOpen(true);
        getBundles();
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      console.log(error);
    }

    setDisabledButton(false);
  };
  const handleDialogClose = () => {
    setOpen(true);
    setIsCreated(false);
  };
  const handleClose = () => {
    setShowScreen(0);
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setAddBundleModal(false);
      setOpenAddBundle(false);
    }
  };
  const handleCloseConfirm = (val) => {
    if (val === 'Discard') {
      setAddBundleModal(false);
      setOpenAddBundle(false);
      setOpen(false);
    }
    if (val === 'Continue') {
      setOpen(false);
    }
  };

  const getmaxSku = async () => {
    // let encodedLocationId = encodeURIComponent(locationId);
    try {
      // let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}bundles/getAllServices?locationId=${encodedLocationId}`);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}bundles/maxSku`
      );
      setBundleSku(response.data.sku);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionLabel = (option) => {
    const { fName, variants, sku } = option;

    if (
      variants &&
      variants?.filter((variant) => variant?.value)?.length === 0
    ) {
      return `${fName} (${sku})`;
    }
    if (variants && variants?.filter((variant) => variant?.value)?.length > 0) {
      const variantLabels = variants
        ?.filter((variant) => variant?.value)
        ?.map((v) => v?.value);
      const variantLabel =
        variantLabels?.length === 1
          ? `${fName} (${variantLabels[0]})`
          : `${fName} (${variantLabels?.join(', ')})`;

      return variantLabel;
    }
    return `${fName} (${sku})`;
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Dialog
        open={openAddBundleModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialogClose()}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right client bg-white"
        maxWidth="xs"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={() => handleDialogClose()}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container maxWidtth="100%" sx={{ paddingTop: '10px' }}>
              <Typography
                className="header-blue-20"
                sx={{ marginBottom: '10px' }}
              >
                {' '}
                Create Bundle
              </Typography>
              <Grid
                container
                component="form"
                spacing={1}
                onSubmit={(e) => AddBundlesData(e)}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="bundleName"
                    fullWidth
                    id="outlined-required"
                    label="BUNDLE NAME"
                    className="input-field-styling required-field"
                    onChange={handleInputChange}
                    value={name}
                    helperText={nameError}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    className="select-drop-down-label-styling"
                    value={pricingValue}
                    name="PricingOptions"
                    options={pricingData || []}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.sortKey}>
                          {option?.name}
                        </li>
                      );
                    }}
                    helperText={nameError}
                    // onChange={handleInputChange}
                    onChange={(event, selectedOptions) => {
                      handleServiceSelection(selectedOptions);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={params?.label || 'ADD PRICING OPTIONS'}
                        className="field-top-margin-edit-location -min-field-height input-field-dropdown-styling select-field "
                        InputLabelProps={{
                          className:
                            'label-style-create-franchise select-staff label-style-text-field  weight-600'
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    className="select-drop-down-label-styling"
                    // value={productValue}
                    name="Product"
                    options={productData || []}
                    // getOptionLabel={(option) => (option.fName+' ('+option.sku+')')}
                    getOptionLabel={(option) => {
                      return getOptionLabel(option);
                    }}
                    // onChange={handleInputChange}
                    onChange={(event, selectedOptions) => {
                      handleProductSelection(selectedOptions);
                      //  setProductValue(selectedOptions);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option?.sortKey}>
                          {getOptionLabel(option)}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={params?.label || 'ADD PRODUCT'}
                        className="field-top-margin-edit-location input-field-dropdown-styling -min-field-height select-field "
                        InputLabelProps={{
                          className:
                            'label-style-create-franchise weight-600 label-style-text-field select-staff'
                        }}
                      />
                    )}
                  />
                  {productError && (
                    <FormHelperText error style={{ marginTop: '10px' }}>
                      {productError}
                    </FormHelperText>
                  )}
                </Grid>
                <p className="bundle-p-tag">
                  Actual Calculated Price: ${totalPricedata}
                </p>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="textfield-bundle"
                  sx={{ paddingTop: '10px' }}
                >
                  <TextField
                    name="discount"
                    fullWidth
                    id="outlined-required"
                    label="APPLY DISCOUNT"
                    className="input-field-styling required-field"
                    value={discount}
                    helperText={discountError}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  {/* <Typography sx={{ marginTop: '5px' }}>Price per credit:{pricePerCredit ? `${pricePerCredit}` : '$0.00'}</Typography> */}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography className="sell-online-txt">
                      <b>Sell online</b>
                    </Typography>
                    <Box className="bundle-toggle">
                      <Toggle
                        value={sellOnline}
                        name="sellOnline"
                        onChange={(e) => toggleButton(e)}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="bundleSku"
                    fullWidth
                    id="outlined-required"
                    label="BUNDLE SKU"
                    className="input-field-styling required-field"
                    onChange={handleInputChange}
                    value={bundleSku}
                    helperText={bundleSkuError}
                    disabled
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="BundlePrice"
                    fullWidth
                    id="outlined-required"
                    label="BUNDLE PRICE"
                    className="input-field-styling required-field"
                    disabled
                    // onChange={handleInputChange}
                    value={'$' + totalDiscountPricedata}
                    // helperText={bundlePriceError}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                  {totalDiscount && (
                    <FormHelperText error>{totalDiscount}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="description"
                    helperText={descriptionError}
                    autocomplete="Description"
                    label="DESCRIPTION"
                    placeholder="Description"
                    variant="outlined"
                    // defaultValue={description}
                    value={description}
                    className="field-top-margin-edit-location required-field "
                    // onChange={(e) => descriptionSet(e.target.value)}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                    InputProps={{ rows: 6 }}
                  />
                </Grid>
                <Grid className="draw-grey-line">
                  <hr className="grey-line-forCreateBundle" />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <CustomDatePickerForAllBrowser
                    label="START DATE"
                    name="startDate"
                    fullWidth
                    disablePast
                    fieldValue={startDate}
                    onChange={validateStartDate}
                    //  required={Boolean(true)}
                  />
                  {startDateError && (
                    <FormHelperText error>{startDateError}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <CustomDatePickerForAllBrowser
                    label="END DATE"
                    name="endDate"
                    fullWidth
                    disablePast
                    value={endDate}
                    onChange={validateEndDate}
                    //  required={Boolean(true)}
                  />

                  {endDateError && (
                    <FormHelperText error>{endDateError}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    type="submit"
                    className="submit-button"
                    fullWidth
                    disabled={disabledButton}
                  >
                    Save Bundle
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? '' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    {' '}
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Bundle has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBundles;
