import { ApiService } from 'src/services/ApiService';

export const franchiseApiRequest = async (
  url,
  franchiseeId,
  setFranchiseWidgetdata,
  locationId,
  date,
  timeZone
) => {
  try {
    const response = await ApiService.post(url, {
      franchiseeId: franchiseeId,
      locationId: locationId,
      date: date,
      timeZone: timeZone
    });

    if (response.statusCode && !response.error) {
      setFranchiseWidgetdata(response?.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const franchisorApiRequest = async (url, setFranchisorWidgetdata) => {
  try {
    const response = await ApiService.post(url);

    if (response.statusCode && !response.error) {
      setFranchisorWidgetdata(response?.data);
    }
  } catch (error) {
    console.log(error);
  }
};
