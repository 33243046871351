/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { ContactSupportOutlined, Reorder } from '@mui/icons-material';
import './ProductList.css';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import GridViewIcon from '@mui/icons-material/GridView';
import EditProduct from './EditProduct';
import { CustomButton, CustomSelect } from 'src/components/Global';

import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Slide,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid,
  Button,
  CardContent,
  CardActions,
  CardMedia,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import Filters from './Filters';
// import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Avatar from '@mui/material/Avatar';
import ProductDetails from './ProductDetails';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ProductGridView = ({
  productLists,
  sideBarfilters,
  setSidebarFilters,
  categoryList,
  totalCount,
  paginationProducts,
  setactivePagination,
  refreshProductData,
  showScreen,
  setShowScreen,
  selectedStatus,
  setSelectedStatus,
  handleBulkAdd,

  selectedProductLists,
  setSelectedProductLists,
  varientType
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedProductItem, setSelectedProductItem] = useState([]);
  const [openProductEditPopup, setopenProductEditPopup] = React.useState(false);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [changeProductView, setchangeProductView] = useState(false);
  const [listViewColor, setListViewColor] = useState('#003087');
  const [gridViewColor, setGridViewColor] = useState('#000000');
  const [open, setOpen] = React.useState(false);
  const selectedSomeProductItems =
    selectedProductLists.length > 0 &&
    selectedProductLists.length < productLists.length;
  const selectedAllProductItems =
    selectedProductLists.length === productLists.length;
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState('asc');
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = productLists.filter((item) => {
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const sortedData = [...filteredData]; // Create a copy of the filtered data array

  sortedData.sort((a, b) => {
    const nameA = a?.fName?.toLowerCase();
    const nameB = b?.fName?.toLowerCase();

    if (sortOrder === 'asc') {
      return nameA?.localeCompare(nameB);
    }
    // else{
    return nameB?.localeCompare(nameA);
    // }
  });
  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  /* Older changes Start */
  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  // Method to get the item for edit
  const getItemForEdit = (id) => {
    let elm;
    setOpenEdit(true);
    setopenProductEditPopup(true);
    // id is the selected location's edit button id which is passed from the child component
    if (id) {
      elm = document.getElementById('edit_list_btn_' + id);
      if (!elm) elm = document.getElementById('edit_grid_btn_' + id);
    }
    if (elm) elm.click();
    // return false;
  };

  // const addNewProduct = () => {
  //   // Now set false for all teams
  //   setShowScreen(1);
  // };

  // Methdo to chage the view
  const changeView = (view) => () => {
    if (view === 'Grid') {
      setchangeProductView(true);
      setListViewColor('#000000');
      setGridViewColor('#003087');
    } else {
      setchangeProductView(false);
      setListViewColor('#003087');
      setGridViewColor('#000000');
    }
  };

  const handleSelectAllProductItems = (event) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n.sortKey);

      setSelectedProductLists(newSelected);
      return;
    }
    setSelectedProductLists([]);
  };

  const handleSelectOneProductItem = (event, productItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedProductLists.includes(productItemId)) {
      setSelectedProductLists((prevSelected) => [
        ...prevSelected,
        productItemId
      ]);
    } else {
      setSelectedProductLists((prevSelected) =>
        prevSelected.filter((id) => id !== productItemId)
      );
    }
  };
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // View location details
  const handleViewProduct = (productItem, e) => {
    // e.preventDefault();
    e.stopPropagation();
    if (e && e.target && e.target.nodeName !== 'INPUT') {
      let Item = productItem;

      // Set the selected location item
      setSelectedProductItem(Item);
      setShowScreen(3);
      // set edit mode false
      setOpenEdit(false);
      // console.log('openEdit View', openEdit);
      handleClickOpen();
    }
  };

  // Location details
  const handleEditProduct = (productItem, e) => {
    e.preventDefault();
    e.stopPropagation();

    let Item = productItem;

    // Set the selected location item
    setSelectedProductItem(Item);
    setShowScreen(2);
    // set edit mode true
    setOpenEdit(true);
    setopenProductEditPopup(true);
    // console.log('openEdit edit', openEdit);
    handleClickOpen();
  };

  /* Older changes End */

  useEffect(() => {
    // setKeys(['name', 'age', 'profession']);
    setPage(0);
  }, [productLists]);

  return (
    <>
      {(() => {
        switch (showScreen) {
          case 2:
            return (
              <EditProduct
                data={selectedProductItem}
                openProductEditPopup={openProductEditPopup}
                setopenProductEditPopup={setopenProductEditPopup}
                refreshProductData={refreshProductData}
                setShowScreen={setShowScreen}
              />
            );
          case 3:
            return (
              <ProductDetails
                data={selectedProductItem}
                getItemForEdit={getItemForEdit}
                setShowScreen={setShowScreen}
                refreshProductData={refreshProductData}
              />
            );

          default:
            return (
              <Grid>
                <Grid xs={12}>
                  {/* <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    className="add-btn-container"
                  >
                    <Grid item>
                      <Button
                        sx={{ mt: { xs: 0, md: 0 } }}
                        variant="contained"
                        className="btn-primary"
                        onClick={addNewProduct}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sx={{
                        marginTop: '10px',
                        marginBottom: '20px'
                      }}
                    >
                      <Button
                        onClick={showFilter}
                        // ref={containerRef}
                        variant={displayFilterForm ? 'contained' : 'outlined'}
                      >
                        Filters
                        <KeyboardArrowDownRoundedIcon
                          className={clsx(
                            !displayFilterForm && classes.closeY,
                            displayFilterForm && classes.openY
                          )}
                        />
                      </Button>
                    </Grid>

                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        marginBottom: '20px'
                      }}
                      className="top-filter-cont"
                    >
                      {/* Search box on table */}
                      <Box
                        className="search-box"
                        sx={{
                          display: 'flex'
                        }}
                      >
                        <Box
                          width={280}
                          sx={{
                            marginRight: '20px'
                          }}
                        >
                          <TextField
                            type="search"
                            label="Search"
                            variant="outlined"
                            className="search-field"
                            onChange={(e) => handleSearchTextChange(e)}
                            //  onClick={handleSearch}
                            fullWidth
                            InputProps={{
                              style: {},
                              endAdornment: !searchText ? <SearchIcon /> : null
                            }}
                            InputLabelProps={{}} // style: { paddingLeft: '10px' }
                            value={searchText}
                          />
                        </Box>
                        <ViewHeadlineIcon
                          onClick={changeView('List')}
                          className="formatAlign cursor-pointer"
                          sx={{
                            fill: `${listViewColor} ! important`
                          }}
                        />
                        <GridViewIcon
                          onClick={changeView('Grid')}
                          className="view-module-icon cursor-pointer"
                          sx={{
                            fill: `${gridViewColor} ! important`
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {displayFilterForm === true ? (
                    <Grid item width={getFilterSize()}>
                      <Filters
                        filters={sideBarfilters}
                        setFilters={setSidebarFilters}
                        categoryList={categoryList}
                        varientType={varientType}
                      />
                    </Grid>
                  ) : // </Slide>
                  null}
                  {changeProductView === true ? (
                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)})`
                      }}
                    >
                      {/* Grid view of the product start */}
                      <Box style={{ padding: '20px', marginTop: '10px' }}>
                        <Grid
                          container
                          spacing={4}
                          className="product-grid-view"
                        >
                          {paginatedData.map((Items) => (
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                              <Card
                                className="product-card"
                                onClick={(e) => handleViewProduct(Items, e)}
                              >
                                <CardContent className="card-content">
                                  {Items.media.length !== 0 ? (
                                    Items.media.map((media, index) =>
                                      index === 0 ? (
                                        <CardMedia
                                          component="img"
                                          image={media.media}
                                        />
                                      ) : null
                                    )
                                  ) : (
                                    <CardMedia
                                      component="img"
                                      image="/static/images/products/ProductImage.png"
                                    />
                                  )}
                                  <EditIcon
                                    className="edit-icon"
                                    id={'edit_grid_btn_' + Items.sortKey}
                                    onClick={(e) => handleEditProduct(Items, e)}
                                  />
                                </CardContent>
                                <CardActions className="card-actions">
                                  <List dense>
                                    <ListItem>
                                      <ListItemText className="product-heading">
                                        {Items.fName}
                                      </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemText className="product-details">
                                        {Items.sku}
                                      </ListItemText>
                                    </ListItem>
                                    {/* <ListItem>
                                      <ListItemText className="product-details">
                                        {Items.price && '$' + Items.price}
                                      </ListItemText>
                                    </ListItem> */}
                                  </List>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      <Box className="-paginate-right">
                        {/* Pagination */}
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20]}
                          component="div"
                          count={filteredData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)})`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              // action={

                              // }
                              // title="All Locations"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedProductLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Activate',
                                  value: 1
                                },
                                {
                                  label: 'Deactivate',
                                  value: 0
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedProductLists?.length
                              }
                              onClick={handleBulkAdd}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>

                        {/* <Divider /> */}
                        <TableContainer className="location-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllProductItems}
                                    indeterminate={selectedSomeProductItems}
                                    onChange={handleSelectAllProductItems}
                                  />
                                </TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Manufacturer Product Name</TableCell>

                                <TableCell>Categories</TableCell>
                                <TableCell sx={{ minWidth: '100px' }}>
                                  Variants
                                </TableCell>
                                <TableCell className="align-center">
                                  Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedData.map((productItem) => {
                                const isProductItemSelected =
                                  selectedProductLists.includes(
                                    productItem.sortKey
                                  );
                                let variantValues = productItem
                                  ? productItem.variants?.map((i) =>
                                      i.value ? i.value : ''
                                    )
                                  : [];
                                return (
                                  <TableRow
                                    hover
                                    key={productItem.sortKey}
                                    selected={isProductItemSelected}
                                    className="table-row cursor-pointer"
                                    onClick={(e) =>
                                      handleViewProduct(productItem, e)
                                    }
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isProductItemSelected}
                                        onChange={(event) =>
                                          handleSelectOneProductItem(
                                            event,
                                            productItem.sortKey
                                          )
                                        }
                                        value={isProductItemSelected}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="table-td"
                                      sx={{ display: 'flex' }}
                                    >
                                      {/* Icon of the franchise */}
                                      {/* {productItem.productMedia.length !== 0 ? (
                                          productItem.productMedia.map((media, index) => */}
                                      {productItem.media.length !== 0 ? (
                                        productItem.media.map((media, index) =>
                                          // <CardMedia component="img" image={media.media} />
                                          index === 0 ? (
                                            <Avatar
                                              alt="icon"
                                              src={media.media}
                                              sx={{
                                                marginRight: 2,
                                                float: 'left'
                                              }}
                                              className="card-avatar-img align-self-center"
                                              id={index}
                                            />
                                          ) : null
                                        )
                                      ) : (
                                        // <CardMedia component="img" image="/static/images/products/ProductImage.png" />
                                        <Avatar
                                          alt="icon"
                                          src={
                                            '/static/images/logo/cardboardicon.png'
                                          }
                                          sx={{ marginRight: 2, float: 'left' }}
                                          className="card-avatar-img align-self-center"
                                        />
                                      )}
                                      {/* <Avatar
                                alt="icon"
                                src={productItem.productImage || "/static/images/logo/cardboardicon.png"}
                                sx={{ marginRight: 2, float: 'left' }}
                                className='card-avatar-img'
                              /> */}
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        gutterBottom
                                      >
                                        {productItem.fName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        gutterBottom
                                      >
                                        {productItem.mfgName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {productItem?.categoryName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {variantValues
                                          ?.filter((value) => value !== '')
                                          ?.map?.((item, index, array) =>
                                            index === array.length - 1
                                              ? item
                                              : `${item}, `
                                          )
                                          .join('') || '-'}
                                        {/* {productItem?.variant?.variantType} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                        className="td-center"
                                      >
                                        {productItem.status === 1 ? (
                                          // Active Status icon
                                          <ActiveStatus />
                                        ) : productItem.status === 0 ? (
                                          // Inactive Status icon
                                          <InactiveStatus />
                                        ) : null}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      {/* <Tooltip title="Edit Location" arrow> */}
                                      <IconButton
                                        sx={{
                                          '&:hover': {
                                            background:
                                              theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        className="edit-btn"
                                        id={
                                          'edit_list_btn_' + productItem.sortKey
                                        }
                                        onClick={(e) =>
                                          handleEditProduct(productItem, e)
                                        }
                                      >
                                        Edit
                                        {/* <EditTwoToneIcon fontSize="small" /> */}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box className="-paginate-right">
                          {/* Pagination */}
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
        }
      })()}
    </>
  );
};

export default ProductGridView;
